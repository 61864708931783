/* Dropdown "context" menu */

.has-context-dropdown-menu {
    position: relative;
    padding-left: 50px;

    .icon {
        margin-left: 0.25rem;
        transition: transform 200ms;
    }

    &.active {
        .icon {
            transform: rotate(180deg);
        }
    }


    span:first-child {
        position: absolute;
        left: -.01rem;
        flex-direction: column;
        justify-content: center;
        user-select: none;
        padding-left: .5rem;
        padding-right: .5rem;
        font-size: 0.65rem;
        color: white;
        background-color: $primary-light;
        border-right: 1px solid var(--line-color);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        height: calc(100% + 2px);
        top: -1px;
        display: inline-flex;
        align-items: center;
    }
}

.context-dropdown-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    box-shadow: 0 2px 3px 4px rgba(#666, 0.1);
    z-index: 20;
    border-radius: 6px;
    font-weight: normal;
    font-family: var(--type-regular);
    text-transform: initial;
    transition: transform 150ms ease, opacity 200ms ease;
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    background-color: var(--background-color);
    border: 1px solid #ccc;
}


.context-dropdown-menu::after,
.context-dropdown-menu::before {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.context-dropdown-menu::after {
    border-color: rgb(255 255 255 / 0%);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
}

.context-dropdown-menu::before {
    border-color: rgb(204 204 204 / 0%);
    border-bottom-color: #ccc;
    border-width: 6px;
    margin-left: -6px;
}

.tools-form-header .context-dropdown-menu {
    top: calc(100% + 5px);
}

.context-dropdown-menu-wide {
    min-width: 600px;
}

.context-dropdown-menu.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.context-dropdown-menu h4 {
    font-size: 0.95rem;
}

.context-dropdown-menu-divider {
    border-top: 1px solid var(--line-color);
}

.context-dropdown-menu li {
    &:not(:last-child) {
        border-bottom: 1px solid var(--line-color);
    }
}



.context-dropdown-menu .success {
    color: $cta-success;
}

.context-dropdown-menu .danger {
    color: $cta-danger;
}

.context-dropdown-menu li.context-dropdown-menu-items-header {
    font-family: var(--type-bold);
    letter-spacing: 0.01em;
    color: var(--text-color);
    font-size: 0.65rem;
    text-transform: uppercase;
}
