.header-stripe {
    background-color: darken($primary, 28%);
    height: $header_height;
    border-bottom: 1px solid darken($primary, 18%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.header-about {
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
}


.header-stripe a:not(.button) {
    font-size: 0.85rem;
    padding: 0.5rem;
    color: lighten($primary, 20%);

    &:hover {
        text-decoration: none;

        .icon {
            color: white;
        }
    }
}

.header-stripe a.header-logo {
    color: white;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--type-bold);
}

.standalone .header-logo {
    margin-left: 74px;
}

.header-logo svg {
    width: 24px;
    height: 24px;
    margin-right: .5rem;
}

.header-logo-title {
    display: block;
    font-weight: 400;
    color: black;
    font-size: 0.85rem;
    margin: 0;
    margin-left: 0.5rem;
    font-family: var(--type-bold);
    letter-spacing: 0.05rem;

    @media (width >= 40em) {
        font-size: 1rem;
    }
}


.header nav {
    display: none;

    @media (width >= 80em) {
        display: flex;
    }
}

.header-stripe nav {
    display: none;

    @include mq(64em) {
        display: flex;
    }
}

.header-presentation p {
    font-size: 0.85rem;
    color: lighten($primary, 20%);
}

/* Video */
.video-screen {
    position: fixed;
    background-color: rgb(0 0 0 / 85%);
    inset: 0;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }

    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

        @media (width >= 40em) {
            max-width: 1130px;
        }
    }
}

/* A service from */
.header-service-from a {
    display: inline-flex;
    text-decoration: none;

    @media (width >= 40em) {
        align-items: center;
    }
}

.header-service-from small {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: lighten($primary, 20%);
    margin-right: 0.5rem;
}

.header-service-from svg {
    width: 75px;
}
