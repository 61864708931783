.learn-more-heading {
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 0.1rem;
}

.learn-more li img {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    margin-right: 1rem;

    @include mq(52em) {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
    }
}

.learn-more li a[href*="youtube"] img {
    @include mq(52em) {
        transform: scale(0.8);
    }
}

.learn-more li a {
    padding: 1rem 1.5rem;
    transition: box-shadow 0.2s ease-in-out 0s;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
        box-shadow: 0 18px 30px 0 rgb(0 0 0 / 12%);
    }

    > a:hover {
        text-decoration: none;
    }
}
