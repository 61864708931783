
// Converted Variables


// Custom Media Query Variables


/* Basscss Button Link */

.button-link {
  padding-right: 0;
  padding-left: 0;
  color: inherit;
  background-color: transparent;
  background-size: 100% .25em;
  background-repeat: repeat-x;
  background-position: 0 1.5rem;
  border-radius: 0;
}

.button-link:hover,
.button-link:focus {
  box-shadow: none;
  background-image: -webkit-linear-gradient(transparent 50%, currentcolor 50%);
  background-image: linear-gradient(transparent 50%, currentcolor 50%);
}

.button-link:active,
.button-link.is-active {
  box-shadow: inset 0 -.125em;
}

.button-link:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px $darken-3;
}

.button-link:disabled,
.button-link.is-disabled {
  opacity: .5;
}