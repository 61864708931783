.spinner {
    position: relative;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.spinner-circle {
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: lighten($primary, 25%) transparent transparent;
    animation: spin 0.5s linear infinite;
    position: absolute;
    inset: 0;
    margin: auto;
    transform-origin: 50% 50%;
}

/* Sizes */
.spinner-tiny {
    width: 20px;
    height: 20px;
}

.spinner-tiny .spinner-circle {
    width: 32px;
    height: 32px;
    border-width: 1px;
}

.spinner-small {
    width: 30px;
    height: 30px;
}

.spinner-small .spinner-circle {
    width: 48px;
    height: 48px;
    border-width: 1px;
}

.spinner-medium {
    width: 42px;
    height: 42px;
}

.spinner-medium .spinner-circle {
    width: 72px;
    height: 72px;
    border-width: 2px;
}

.spinner-large {
    width: 68px;
    height: 68px;
}

.spinner-large .spinner-circle {
    width: 104px;
    height: 104px;
    border-width: 3px;
}

/* Cuity logo */
.spinner-logo {
    width: 80px;
    height: 80px;
    position: relative;
    left: calc(25%);
    fill: $primary;
}

.spinner-inverted .spinner-circle {
    border-color: white transparent transparent;
}

/* Lines */
.spinner-top-line {
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0.5s;
    animation: top-animation 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) 0s infinite;
}

.spinner-middle-line {
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0.5s;
    animation: middle-animation 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) 0.45s
        infinite;
}

.spinner-tiny-line {
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0.5s;
    animation: tiny-animation 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) 1s
        infinite;
}

.spinner-bottom-line {
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0.5s;
    animation: bottom-animation 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) 0.6s
        infinite;
    transform-origin: 0% 50%;
}

/* Keyframes */
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes bottom-animation {
    0% {
        transform: translateX(9px);
    }

    50% {
        transform: translateX(4px);
        opacity: 1;
    }

    100% {
        transform: translateX(2px);
        opacity: 0;
    }
}

@keyframes tiny-animation {
    0% {
        transform: translateX(2px);
    }

    50% {
        transform: translateX(2px);
        opacity: 1;
    }

    100% {
        transform: translateX(2px);
        opacity: 0;
    }
}

@keyframes top-animation {
    0% {
        transform: translateX(16px);
    }

    50% {
        transform: translateX(8px);
        opacity: 1;
    }

    100% {
        transform: translateX(2px);
        opacity: 0;
    }
}

@keyframes middle-animation {
    0% {
        transform: translateX(16px);
    }

    50% {
        transform: translateX(8px);
        opacity: 1;
    }

    100% {
        transform: translateX(2px);
        opacity: 0;
    }
}
