.tools-history-header {
    background-color: darken($primary, 28%);
    position: sticky;
    top: 0;
    z-index: 1;
}

.tools-history-header .button {
    border: none;
    color: $primary;

    &:hover {
        background-color: darken($primary, 20%);
        color: white;
    }
}
