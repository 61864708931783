.about-page {
  --notice-from-bottom: 2rem;
}

.notice {
  --notice-height: 270px;
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --notice-from-bottom: 0;

  position: fixed;
  width: 100%;
  height: var(--notice-height);
  bottom: var(--notice-from-bottom);
  right: 0;
  z-index: 2;
  background-color: $bright;
  box-shadow: rgb(255 255 255) 0 0 0 0, rgb(0 0 0 / 5%) 0 0 0 1px,
    rgb(0 0 0 / 10%) 0 10px 15px -3px, rgb(0 0 0 / 5%) 0 4px 6px -2px;
  padding: 2rem;
  transition: transform 200ms var(--ease-in-out-cubic);
  transform: translateY(calc(var(--notice-height) + var(--notice-from-bottom)));

  @include mq(40em) {
    --notice-from-bottom: 2vmin;

    right: 2rem;
    width: 360px;
    border-radius: 6px;
  }
}

.notice-popout {
  animation-timing-function: var(--ease-in-out-cubic);
  animation-name: notice-slide-up;
  animation-duration: 500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}

.notice-popout a.button {
  color: white;
  border-color: white;
}

.notice-hidden {
  animation-name: notice-slide-down;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes notice-slide-up {
  0% {
    transform: translateY(
      calc(var(--notice-height) + var(--notice-from-bottom))
    );
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes notice-slide-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(
      calc(var(--notice-height) + var(--notice-from-bottom))
    );
  }
}

.notice h3 {
  font-size: 1.25rem;
}

.notice svg {
  max-width: 65px;
  max-height: 40px;

  &:first-of-type {
    margin-right: 0.2rem;
  }

  * {
    fill: white;
  }
}

.notice-close {
  color: white;
  position: absolute;
  right: 1rem;
  top: 1rem;
  box-shadow: initial;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: none;
  }
}

.notice p {
  line-height: 1.2;
  color: white;
}
