* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

*:focus:not(.focus-visible) {
  outline: none;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-size: 100%;
  font-family: Roboto-Regular, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overscroll-behavior-y: none;
  background-color: var(--background-color);
  color: var(--text-color);
}

html,
body {
  overflow: initial;
}

body.dimmed::after {
  content: "";
  background-color: rgb(0 0 0 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 60em;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin: 0 auto;

  @include mq(52em) {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}

.container-wide {
  max-width: 80em;
}

/* Color scheme switcher */
select.color-scheme-switcher {
  background-color: transparent;
  font-size: .75rem;
  padding: .15rem .25rem;
  border: none;
  height: 40px;
  appearance: none;
  color: transparent

}

.color-scheme-switcher-holder {
  svg {
    margin: 0 auto;
    text-align: center;
    display: block;
    pointer-events: none;
    transform: translateY(20px);
  }

}
