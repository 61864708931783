/* -------------------------------------------------------------------
Media queries
------------------------------------------------------------------- */


@mixin mq($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}
