/* Links */

a {
    transition: color 200ms ease;
}

/* Improves readability when focused and also mouse hovered in all browsers
people.opera.com/patrickl/experiments/keyboard/test */

a:hover,
a:active {
    outline: 0;
}
