.request-illustration-box {
    box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
    transition: opacity 200ms ease, transform 300ms ease;
    border-radius: 4px;
    padding: 2em;
    font-size: 0.85rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.request-illustration-box-fetch {
    background-color: var(--background-color);
    color: $key;
    border: 1px solid $key;
}

.request-illustration-box-server {
    background-color: var(--background-color);
    color: $green;
    border: 1px solid $green;
}

.request-illustration-lines-line {
    position: relative;
    min-width: 200px;
    height: 1px;
    background-image: linear-gradient(to right, silver 50%, transparent 0%);
    background-size: 8px 4px;
    background-repeat: repeat-x;
    background-position: 0% bottom;
    animation-name: border-dance;
    animation-duration: 24s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes border-dance {
    from {
        background-position: 0% bottom;
    }

    to {
        background-position: 100% bottom;
    }
}

.request-illustration-lines-line::after {
    content: '';
    position: absolute;
    right: -12px;
    top: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #aaa;
}

.request-illustration-lines-line-code::before {
    content: 'code';
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 0.75rem;
    top: -1.5rem;
    margin: 0 auto;
    display: block;
}

.request-illustration-lines-line-token::before {
    content: 'tokens';
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 0.75rem;
    top: -1.5rem;
    margin: 0 auto;
    display: block;
    transform: rotate(180deg);
}

.request-illustration-lines-line-token {
    background-image: linear-gradient(to right, $green 50%, transparent 0%);
    transform: rotate(180deg);

    &::after {
        border-color: transparent transparent transparent $green;
    }
}

.request-illustration-lines-line-code {
    background-image: linear-gradient(to right, $key 50%, transparent 0%);

    &::after {
        border-color: transparent transparent transparent $key;
    }
}

.blinking {
    animation: blinker 1.5s linear infinite;
}

.blinking:hover {
    animation: none;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}
