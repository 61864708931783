/* Filter */

input[type="search"].tools-history-filter {
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    padding-left: 2rem;
    background-color: darken($primary, 20%);
    box-sizing: border-box;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: 0.5rem center;
    background-image: url("data:image/svg+xml,%3Csvg height='20px' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='20px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23969696' d='M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3 c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2 c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2 c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z'/%3E%3C/svg%3E");
    transition: border-color 0.15s ease-in-out;
    height: 32px;

    &::placeholder {
        color: lighten($primary, 20%);
    }

    &:focus {
        background-color: var(--background-color);
        color: var(--text-color);
        outline: none;
        border-color: var(--line-color);
        box-shadow: 0 0 0 2px rgba($primary, 0.25);
        background-image: url("data:image/svg+xml,%3Csvg height='20px' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='20px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23000' d='M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3 c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2 c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2 c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z'/%3E%3C/svg%3E");

        &::placeholder {
            color: var(--text-color);
        }
    }

    &.active {
        display: block;
    }
}

.tools-history-header button.field-clear {
    right: calc(8px);
}

.tools-history-header .show-password {
    background-color: transparent;
}
