@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Roboto Mono"), local("RobotoMono-Regular"),
    url("/static_assets/fonts/roboto-mono-v6-latin-regular.woff2")
      format("woff2");
}

address {
  font-style: normal;
}

strong,
b {
  font-weight: normal;
  font-family: var(--type-bold);
}

i,
em {
  font-style: italic;
}

html {
  text-size-adjust: 100%;
  background: rgb(255 255 255);
  overflow-y: auto;
}

/*
SELECTIONS
--------------------------------------------- */

::selection {
  background: #2f4461;
  color: rgb(255 255 255);
  text-shadow: none;
}

::selection:window-inactive {
  background: #2f4461;
  color: rgb(50 50 50);
}

img::selection {
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-feature-settings: "kern", "liga", "clig", "calt";
  text-rendering: optimizelegibility;
  font-weight: normal;
  font-family: var(--type-regular);
  color: var(--text-color);
}

.h1,
h1 {
  font-size: 2rem;

  @include mq(64em) {
    font-size: 3rem;
  }
}

.h2,
h2 {
  font-size: 1.5rem;

  @include mq(64em) {
    font-size: 2.4rem;
  }
}

.h3,
h3 {
  font-size: 1.25rem;

  @include mq(64em) {
    font-size: 1.55rem;
  }
}

.h4,
h4 {
  font-size: 0.95rem;

  @include mq(64em) {
    font-size: 1.05rem;
  }
}

p {
  font-size: 0.85rem;
  line-height: 1.85;
  color: var(--text-color);

  @include mq(64em) {
    font-size: 0.9rem;
  }
}

.article p {
  @include mq(64em) {
    font-size: 1.05rem;
  }
}

.article ul {
  @include mq(64em) {
    font-size: 1.05rem;
  }
}

abbr {
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.1em;
  cursor: help;
}

sub,
sup {
  vertical-align: baseline;
  position: relative;
  font-size: 65%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

blockquote {
  margin: 2.5em 0;
  color: rgb(90 90 90);
  position: relative;
  font-style: normal;
  background-color: lighten($tertiary, 15%);
  border-left: 4px solid lighten($tertiary, 5%);
  padding: 1rem 2rem;

  p {
    margin: 0;
  }
}

ul {
  list-style-type: disc;
}

ul,
ol {
  margin-left: 0;
  font-size: 0.85rem;
  line-height: 1.85;

  @include mq(64em) {
    font-size: 1rem;
  }
}

code {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.article {
  max-width: 1200px;
  margin: 0 auto;
}
