.CookieConsent {
  background: white;
  padding: 1rem;
  color: initial;
  border-radius: 8px;
  max-width: 500px;
  font-size: 14px;
  border: 1px solid #ccc;
  position: fixed;
  margin: 1rem;
  bottom: 1rem;
  right: 0;
  z-index: 99999;
}

.CookieConsent a {
  color: $bright;
  text-decoration: underline;
}

#rcc-confirm-button,
#rcc-decline-button {
  color: white;
  background-color: black;
  font-size: 14px;
  border-radius: 8px;
  padding: 0.5rem 1.2rem;
  margin: 0.5rem;
  border: 1px solid black;
}

#rcc-decline-button {
  color: black;
  background-color: white;
}

#rcc-decline-button:hover {
  color: white;
}

#rcc-confirm-button:hover,
#rcc-decline-button:hover {
  background-color: #666;
}
