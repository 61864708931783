.tools-history-badge {
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    padding: 0.15rem 0.35rem;
    margin-right: 0.5rem;
    width: 69px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    font-family: var(--type-mono);
    cursor: move;
}

.tools-history-badge-jwt {
    background-color: $jwt;
}

.tools-history-badge-create_jwt {
    background-color: $createjwt
}

.tools-history-badge-logout {
    background-color: $logout;
}

.tools-history-badge-implicit {
    background-color: $implicitflow;
}

.tools-history-badge-code {
    background-color: $codeflow;
}

.tools-history-badge-hybrid {
    background-color: $hybridflow;
}

.tools-history-badge-cc {
    background-color: $clientcredentials;
}

.tools-history-badge-exchange {
    background-color: $tokenexchange;
}

.tools-history-badge-ropc {
    background-color: $ropc;
}

.tools-history-badge-userinfo {
    background-color: $userinfo;
}

.tools-history-badge-dcr {
    background-color: $dcr;
}

.tools-history-badge-dcrm {
    background-color: $dcrm;
}

.tools-history-badge-apicall {
    background-color: $apicall;
}

.tools-history-badge-pre {
    background-color: $precode;
}

.tools-history-badge-vci {
    background-color: $vci;
}

.tools-history-badge-refresh {
    background-color: $refresh;
}

.tools-history-badge-revoke {
    background-color: $revoke;
}

.tools-history-badge-introspect {
    background-color: $introspectionflow;
}

.tools-history-badge-device {
    background-color: $device;
}

.tools-history-badge-ciba {
    background-color: $ciba;
}
