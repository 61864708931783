.tools-history .drop-target {
    border: 1px dashed $primary;
    border-radius: 6px;
    margin: .5rem;
    transition: margin 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.tools-history-group {
    color: $primary;
    cursor: pointer;
    padding: .25rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
}

.tools-history-group .tools-history-entry {
    font-size: .95rem;
}

.drop-target .tools-history-group {
    color: $primary;
}
