
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Utility Responsive States */

.sm-show, .md-show, .lg-show {
  display: none !important
}

@media #{$breakpoint-sm} {
  .sm-show { display: block !important }
}

@media #{$breakpoint-md} {
  .md-show { display: block !important }
}

@media #{$breakpoint-lg} {
  .lg-show { display: block !important }
}


@media #{$breakpoint-sm} {
  .sm-hide { display: none !important }
}

@media #{$breakpoint-md} {
  .md-hide { display: none !important }
}

@media #{$breakpoint-lg} {
  .lg-hide { display: none !important }
}

.display-none { display: none !important }

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}


/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/
