.toast {
  @extend .alert;
  @extend .alert-success;

  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 200;
  text-transform: initial;
  transition: transform 600ms ease, opacity 300ms ease;
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
}

.toast-active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.toast-shown {
  animation: fadeout 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

.toast.toast-error {
  @extend .alert-danger;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.toast svg {
  width: 20px;
  display: block;
}

/* Curity Release Notice adjustments */
.curityToast-info {
  max-width: 500px;
  margin: 1rem;
  bottom: 1rem;
  right: 0;
  left: auto;
  transform: unset;
  animation: none;
}

body:has(.CookieConsent) .curityToast-info {
  bottom: 160px;
}
