
.tooltip-holder {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    margin-left: .5rem;

}


[data-tooltip] {
    position: relative;
}

/* Copied state */
[data-tooltip][data-tool-tip-copied="true"]::after {
    background-color: $cta-success
}

[data-tooltip][flow^="left"][data-tool-tip-copied="true"]::before {
    border-left-color: $cta-success;
}

[data-tooltip][flow^="right"][data-tool-tip-copied="true"]::before {
    border-right-color: $cta-success;
}

[data-tooltip]:not([flow])[data-tool-tip-copied="true"]::before,
[data-tooltip][flow^="up"][data-tool-tip-copied="true"]::before {
    border-top-color: $cta-success;
}


[data-tooltip]::before,
[data-tooltip]::after {
    text-transform: none;
    font-size: 0.75rem;
    line-height: 1.2;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

[data-tooltip]data[tooltip-visible]::before,
[data-tooltip]data[tooltip-visible]::after {
    display: block;
    opacity: 1;
}

[data-tooltip]::before {
    content: "";
    border: 5px solid transparent;
    z-index: 1001;
}

[data-tooltip]::after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 100px;
    max-width: 200px;
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
    background: black;
    color: #fff;
    z-index: 1000;
    font-size: 0.75rem;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
    display: block;
}

data[tooltip=""]::before,
data[tooltip=""]::after {
    display: none !important;
}

/* Positions */
[data-tooltip]:not([flow])::before,
[data-tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
}

[data-tooltip]:not([flow])::after,
[data-tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
}

[data-tooltip]:not([flow])::before,
[data-tooltip]:not([flow])::after,
[data-tooltip][flow^="up"]::before,
[data-tooltip][flow^="up"]::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
}

[data-tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
}

[data-tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
}

[data-tooltip][flow^="down"]::before,
[data-tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, 0.5em);
}

[data-tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-0.5em, -50%);
}

[data-tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-0.5em, -50%);
}

[data-tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #333;
    right: calc(0em - 5px);
    transform: translate(0.5em, -50%);
}

[data-tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(0.5em, -50%);
}

@keyframes tooltips-vert {
    to {
        opacity: 0.9;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: 0.9;
        transform: translate(0, -50%);
    }
}

[data-tooltip]:not([flow]):hover::before,
[data-tooltip]:not([flow]):hover::after,
[data-tooltip][flow^="up"]:hover::before,
[data-tooltip][flow^="up"]:hover::after,
[data-tooltip][flow^="down"]:hover::before,
[data-tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

[data-tooltip][flow^="left"]:hover::before,
[data-tooltip][flow^="left"]:hover::after,
[data-tooltip][flow^="right"]:hover::before,
[data-tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}
