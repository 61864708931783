
.standalone body {
    padding-top: 41px;
}

.standalone .header-stripe {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2147483647;
    -webkit-app-region: drag;
    user-select: none;

    &:hover {
        cursor: grab;
    }
}

.standalone .header-stripe a,
.standalone .header-stripe a i {
    -webkit-app-region: no-drag;

    &:hover {
        cursor: pointer;
    }
}

.standalone .tools-history {
    overflow-y: auto;
    padding-bottom: 2rem;
}

.standalone .collapsed .tools-history {
    overflow-y: inherit;
}

html.standalone {
    overflow-y: hidden;
}

.standalone .about {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 2rem;
}

.update-notification {
    position: fixed;
    bottom: 20px;
    top: initial!important;
    left: 20px;
    width: 260px!important;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    color: $primary-light;
}

[data-color-scheme="dark"] .update-notification {
    background-color: $primary;
}

.update-notification .button-close {
    color: $primary-light;
    transition: color 300ms;
    position: absolute;
    top: .5rem;
    right: 1rem;
    padding: 0;
    box-shadow: none;
    font-size: 1.5rem;

    &:hover {
        background-color: transparent;
        color: $primary-dark;
    }
}
