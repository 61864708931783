
// Converted Variables


// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Table Object */

.table {
  display: table;
  width: 100%;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.table-fixed { table-layout: fixed }
@media #{$breakpoint-sm} {

  .sm-table {
    display: table;
    width: 100%;
  }
  .sm-table-cell {
    display: table-cell;
    vertical-align: middle;
  }

}
@media #{$breakpoint-md} {

  .md-table {
    display: table;
    width: 100%;
  }
  .md-table-cell {
    display: table-cell;
    vertical-align: middle;
  }

}
@media #{$breakpoint-lg} {

  .lg-table {
    display: table;
    width: 100%;
  }
  .lg-table-cell {
    display: table-cell;
    vertical-align: middle;
  }

}