#content > .error-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.error-page {
    max-width: 600px;
    padding: 3rem;
}

.error-page ul {
    list-style: none;
}

.error-page ul li::before {
    content: "\2022";
    color: $bright;
    font-weight: bold;
    display: inline-block;
    transform: scale(2);
    line-height: 1;
    width: 1em;
    margin-left: -1em;
}

.error-page p {
    font-size: 1rem;
}
