.content {
  position: relative;

  @media (width >= 40em) {
    top: -10vh;
  }
}

.tools-sidebar-empty h2 {
  font-size: 2rem;
}

.tools-sidebar-empty-holder {
  height: calc(100vh - #{$header_height} - 45px - #{$empty-state-arrow-height});
}

.pulse-stay,
.pulse {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba($bright, 1);
  will-change: transform;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(90deg);
  pointer-events: none;

  @include mq(64em) {
    transform: initial;
  }
}

.pulse {
  animation: pulse 2.5s infinite;
}

.pulse-down {
  transform: rotate(-90deg);
}

.pulse-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse-wrapper svg {
  animation-name: bounce-left-right;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

/* Small */
.pulse-wrapper-small .pulse-stay,
.pulse-wrapper-small .pulse {
  width: 24px;
  height: 24px;
}

.pulse-wrapper-small .icon {
  font-size: 1rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  80% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
