/* Main */


.tools-form-header {
    padding: 0.5rem;
    border-bottom: 1px solid var(--line-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: var(--background-color);
}

.tools-form-header-justify {
    justify-content: space-between;
}

.tools-form-content {
    padding: 1rem;

    @include mq(52em) {
        padding: 1.5rem;
    }
}
