:root {
    --curityToastDuration: 5000;
    --curityToastFromBottom: 3rem;
    --curityToastHeight: 75px;
    --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

.curityToast {
    box-sizing: border-box;
    position: fixed;
    min-height: var(--curityToastHeight);
    bottom: var(--curityToastFromBottom);
    left: 50%;
    padding: .75rem 1.5rem;
    border-radius: 6px;
    font-family: Roboto, Roboto-Regular, system-ui, serif;
    min-width: 90%;
    max-width: calc(100vw - 64px);
    box-shadow: 0 1px 3px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 15%);
    background-color: rgb(51 51 51);
    color: white;
    font-size: 1rem;
    z-index: 99999999;
    line-height: 1.2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 150ms var(--ease-in-expo);
    transform: translate(-50%, calc(var(--curityToastHeight) + var(--curityToastFromBottom)));
}

.curityToast > div {
    max-width: calc(100% - 50px);
}

.curityToastPopout {
    animation-timing-function: var(--ease-in-out-cubic);
    animation-name: slide-up;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
}

.curityToastHidden {
    animation-name: slide-down;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
}

@keyframes slide-up {
    0% {
        transform: translate(-50%, calc(var(--curityToastHeight) + var(--curityToastFromBottom)));
    }

    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes slide-down {
    0% {
        transform: translate(-50%, 0);
    }

    100% {
        transform: translate(-50%, calc(var(--curityToastHeight) + var(--curityToastFromBottom)));
    }
}

@media only screen and (width >= 40em) {
    .curityToast {
        font-size: 1rem;
        min-width: 240px;
        max-width: calc(100vw - 64px);
    }
}

.curityToast h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 1.25rem;
    color: white;
}

.curityToast p {
    margin: 0;
    color: white;
}

.curityToast span {
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: white;
    border: none;
    appearance: none;
}

.curityToast button {
    margin-left: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    transition: all 200ms;
    font-size: 1rem;
    vertical-align: middle;
    outline: none;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background-color: transparent;
    color: white;
    border: none;
    appearance: none;
    cursor: pointer;
    padding: initial;
    box-shadow: none !important;
}

.curityToast button:hover {
    box-shadow: 0 0 0 3px rgb(66 153 225 / 30%);
    background-color: white;
    color: initial;
}

.curityToast button:focus {
    box-shadow: 0 0 0 3px rgb(66 153 225 / 60%);
}

.curityToast.stay {
    animation: stay 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.curityToast-success {
    background-color: #00c853;
}

.curityToast-danger {
    background-color: #f44336;
}

.curityToast-warning {
    background-color: orange;
}

.curityToast-info {
    background-color: #1a202c;
}

.curityToast-info a {
    color: lightgoldenrodyellow
}


.curityToast-notify {
    justify-content: center;
}

@keyframes stay {
    from {
        transform: translateX(-50%) scale(0.87);
        opacity: 0;
    }

    to {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }
}

@keyframes pop {
    0% {
        transform: translateX(-50%) scale(0.87);
        opacity: 0;
    }

    3.75%,
    96.25% {
        transform: translateX(-50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) scale(1);
        animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
        opacity: 0;
    }
}
