/* The switch - the box around the slider */

.custom-checkbox > label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    font-size: 0.8rem;
    word-break: break-all;
    color: var(--text-color)
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

/* Hide default HTML checkbox */

.toggle-switch input {
    display: none;
}

/* The slider */

.toggle-slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: var(--toggle-background-color);
    transition: 0.2s;
}

.toggle-slider-switch {
    background-color: $key;
}

.toggle-slider::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.2s;
}

input:checked + .toggle-slider {
    background-color: $cta-success;
}

input:checked + .toggle-slider-switch {
    background-color: $key;
}

input:checked + .toggle-slider::before {
    transform: translateX(26px);
}

input:disabled + .toggle-slider {
    background-color: #f1f1f1;
}

/* Rounded sliders */

.toggle-slider.round {
    border-radius: 34px;
}

.toggle-slider.round::before {
    border-radius: 50%;
}
