
.kid {
	overflow: hidden;
	max-width: 80px;
	display: inline-block;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.modal-environments .kid {
	max-width: 400px;
}
