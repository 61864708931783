/* Delete */

.tools-history-edit {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 1.85rem;
    top: calc(50% - 10px);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;

    .icon {
        transition: color 300ms;
    }

    &:hover {
        color: white;
    }
}

.tools-history-edit.active {
    color: white;
    opacity: 1;
}

.tools-history li:hover .tools-history-edit {
    opacity: 1;
}

.tools-history-group .tools-history-edit {
    opacity: 1;
}


.tools-history .context-dropdown-menu i::before {
    width: 12px;
}

.tools-history .context-dropdown-menu {
    min-width: 145px;
    right: 0.65rem;
}
