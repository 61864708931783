.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 80%);
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.modal-claims {
  width: 100%;
  top: $header_height;

  --sidebar-width: 20%;
  --inset: 20px;
  --header-height: 41px;

  /* Keep Claims modal inside the main panel, with a little offset (inset padding) */
  .modal-main {
    top: calc(var(--header-height) + var(--inset));
    left: calc(var(--sidebar-width) + var(--inset));
    width: calc(100vw - (var(--sidebar-width) * 2) - var(--inset));
  }
}

.modal-main {
  --inset: 20px;
  --header-height: 41px;
  --footer-height: 40px;

  position: fixed;
  background: var(--background-color);
  height: auto;
  box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
  transition: opacity 200ms ease;
  border-radius: 3px;
  opacity: 0;
  top: 0;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;

  @media (width >= 60em) {
    top: calc(var(--header-height) + var(--inset));
    max-height: calc(
      100vh - var(--header-height) - var(--footer-height) -
        calc(var(--inset) * 2)
    );
    width: 800px;
  }
}

/* Modal header */
.modal-main-header {
  position: sticky;
  height: 60px;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--line-color);
  background-color: var(--background-color);
  z-index: 15;
}

.modal-main-header .button-close {
  padding-left: 0;
  padding-right: 0;

  .ion-close {
    color: var(--text-color);
    transition: color 300ms;
  }

  &:hover {
    background-color: transparent;
  }
}

/* Modal content */
.modal-main-content {
  padding: 1rem;

  @media (width >= 60em) {
    padding: 2rem;
  }
}

/* Variants  */
.modal-main-content-flows {
  background-color: var(--inner-background-color);
}

.modal-main-wide {
  width: 100%;

  @media (width >= 60em) {
    width: 1400px;
  }
}

.modal-main h2 {
  margin-top: 0;
}

@keyframes zoom {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.modal-is-visible {
  opacity: 1;
  visibility: visible;

  .modal-main {
    opacity: 1;
    animation-name: zoom;
    animation-duration: 0.2s;
  }
}

.modal-is-hidden {
  opacity: 0;
  visibility: hidden;
}

/* Radio buttons */

.action-item-wrapper {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;

  @media (width >= 60em) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
}

.action-item label {
  padding: 0.5rem;
  background-color: var(--background-color);
  box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 150ms ease, background-color 200ms, box-shadow 150ms;
  backface-visibility: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (width >= 60em) {
    padding: 0.5rem 1rem;
  }

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    appearance: none;
    box-shadow: 0 0 0 1px rgb(128 128 128 / 15%);
    outline: 0;
    color: white;
  }
}

.action-item input {
  display: none;
}

.action-item label:hover,
.action-item label:focus {
  background-color: $primary;
  color: white;
}

/* Color variants */

.action-item-jwt label {
  border-left: 8px solid $jwt;
}

.action-item-create_jwt label {
  border-left: 8px solid $createjwt;
}

.action-item-implicitflow label {
  border-left: 8px solid $implicitflow;
}

.action-item-codeflow label {
  border-left: 8px solid $codeflow;
}

.action-item-hybrid label {
  border-left: 8px solid $hybridflow;
}

.action-item-clientcredentials label {
  border-left: 8px solid $clientcredentials;
}

.action-item-tokenexchange label {
  border-left: 8px solid $tokenexchange;
}

.action-item-ropc label {
  border-left: 8px solid $ropc;
}

.action-item-userinfo label {
  border-left: 8px solid $userinfo;
}

.action-item-dcr label {
  border-left: 8px solid $dcr;
}

.action-item-dcrm label {
  border-left: 8px solid $dcrm;
}

.action-item-apicall label {
  border-left: 8px solid $apicall;
}

.action-item-precode label {
  border-left: 8px solid $precode;
}

.action-item-vci label {
  border-left: 8px solid $vci;
}

.action-item-refresh label {
  border-left: 8px solid $refresh;
}

.action-item-revoke label {
  border-left: 8px solid $revoke;
}

.action-item-introspect label {
  border-left: 8px solid $introspectionflow;
}

.action-item-device label {
  border-left: 8px solid $device;
}

.action-item-ciba label {
  border-left: 8px solid $ciba;
}

.action-item-logout label {
  border-left: 8px solid $logout;
}

.action-item-jwt label:hover,
.action-item-jwt label:focus {
  background-color: $jwt;
}

.action-item-create_jwt label:hover,
.action-item-create_jwt label:focus {
  background-color: $createjwt;
}

.action-item-implicitflow label:hover,
.action-item-implicitflow label:focus {
  background-color: $implicitflow;
}

.action-item-codeflow label:hover,
.action-item-codeflow label:focus {
  background-color: $codeflow;
}

.action-item-hybrid label:hover,
.action-item-hybrid label:focus {
  background-color: $hybridflow;
}

.action-item-logout label:hover,
.action-item-logout label:focus {
  background-color: $logout;
}

.action-item-clientcredentials label:hover,
.action-item-clientcredentials label:focus {
  background-color: $clientcredentials;
}

.action-item-tokenexchange label:hover,
.action-item-tokenexchange label:focus {
  background-color: $tokenexchange;
}

.action-item-ropc label:hover,
.action-item-ropc label:focus {
  background-color: $ropc;
}

.action-item-userinfo label:hover,
.action-item-userinfo label:focus {
  background-color: $userinfo;
}

.action-item-dcr label:hover,
.action-item-dcr label:focus {
  background-color: $dcr;
}

.action-item-dcrm label:hover,
.action-item-dcrm label:focus {
  background-color: $dcrm;
}

.action-item-apicall label:hover,
.action-item-apicall label:focus {
  background-color: $apicall;
}

.action-item-precode label:hover,
.action-item-c label:focus {
  background-color: $precode;
}

.action-item-vci label:hover,
.action-item-vci label:focus {
  background-color: $vci;
}

.action-item-refresh label:hover,
.action-item-refresh label:focus {
  background-color: $refresh;
}

.action-item-revoke label:hover,
.action-item-revoke label:focus {
  background-color: $revoke;
}

.action-item-introspect label:hover,
.action-item-introspect label:focus {
  background-color: $introspectionflow;
}

.action-item-device label:hover,
.action-item-device label:focus {
  background-color: $device;
}

.action-item-ciba label:hover,
.action-item-ciba label:focus {
  background-color: $ciba;
}

/* Panel */
.modal-panel {
  position: relative;
}

main .modal-panel-section > h3,
main .modal-panel-section:nth-child(2) > div {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

main .modal-panel-section > h3,
main .modal-panel-section:nth-child(2) > div:first-of-type {
  position: sticky;
  top: 125px;
  background-color: var(--background-color);
  z-index: 5;
}

.modal-panel aside {
  background-color: var(--background-color);
  position: sticky;
  top: 60px;
  z-index: 10;
}

.modal-panel aside li:not(:last-child) {
  margin-right: 0.5rem;
}

.modal-panel aside a {
  padding: 0.25rem 0.75rem;
  display: block;
  font-size: 0.85rem;
  color: var(--text-color);
  border-radius: 4px;

  &:hover {
    text-decoration: none;
    background-color: var(--inner-background-color);
  }

  &.active {
    font-family: var(--type-bold);
    color: white;
    background-color: $primary;
  }
}

/* Overlay */
.modal-overlay {
  background: white;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-overlay-content {
  background-color: var(--inner-background-color);
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  height: calc(80vh - 128px - 2rem);
}
