
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Utility White Space */

.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }

.m1  { margin:        $space-1 }
.mt1 { margin-top:    $space-1 }
.mr1 { margin-right:  $space-1 }
.mb1 { margin-bottom: $space-1 }
.ml1 { margin-left:   $space-1 }

.m2  { margin:        $space-2 }
.mt2 { margin-top:    $space-2 }
.mr2 { margin-right:  $space-2 }
.mb2 { margin-bottom: $space-2 }
.ml2 { margin-left:   $space-2 }

.m3  { margin:        $space-3 }
.mt3 { margin-top:    $space-3 }
.mr3 { margin-right:  $space-3 }
.mb3 { margin-bottom: $space-3 }
.ml3 { margin-left:   $space-3 }

.m4  { margin:        $space-4 }
.mt4 { margin-top:    $space-4 }
.mr4 { margin-right:  $space-4 }
.mb4 { margin-bottom: $space-4 }
.ml4 { margin-left:   $space-4 }

.mxn1 { margin-left: -$space-1; margin-right: -$space-1; }
.mxn2 { margin-left: -$space-2; margin-right: -$space-2; }
.mxn3 { margin-left: -$space-3; margin-right: -$space-3; }
.mxn4 { margin-left: -$space-4; margin-right: -$space-4; }

.mx-auto { margin-left: auto; margin-right: auto; }
.p0 { padding: 0 }

.p1  { padding:       $space-1 }
.py1 { padding-top:   $space-1; padding-bottom: $space-1 }
.px1 { padding-left:  $space-1; padding-right:  $space-1 }

.p2  { padding:       $space-2 }
.py2 { padding-top:   $space-2; padding-bottom: $space-2 }
.px2 { padding-left:  $space-2; padding-right:  $space-2 }

.p3  { padding:       $space-3 }
.py3 { padding-top:   $space-3; padding-bottom: $space-3 }
.px3 { padding-left:  $space-3; padding-right:  $space-3 }

.p4  { padding:       $space-4 }
.py4 { padding-top:   $space-4; padding-bottom: $space-4 }
.px4 { padding-left:  $space-4; padding-right:  $space-4 }

/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/