.radio-label {
    background: white;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 1rem 0.25rem 0.75rem;
    position: relative;
    transition: 150ms ease all;
    width: 100%;
}

.radio-btn-group div:nth-child(1) .radio-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.radio-btn-group div:nth-child(2) .radio-label {
    border-radius: 0;
}

.radio-btn-group div:nth-child(3) .radio-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.radio-label:hover {
    box-shadow: 0 4px 8px rgb(0 0 0 / 5%);
}

.radio-label::before {
    background-color: #f2f2f2;
    border-radius: 50%;
    content: "";
    height: 20px;
    left: 20px;
    top: calc(50% - 15px);
    transition: 0.3s ease background-color;
    width: 20px;
    margin-right: 0.5rem;
}

.radio-label span {
    user-select: none;
}

.radio-btn {
    position: absolute;
    visibility: hidden;
}

.radio-btn:checked + .radio-label {
    background-color: lighten($primary, 50%);
    border-color: lighten($primary, 30%);
}

.radio-btn:checked + .radio-label::before {
    background-color: $primary;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNiIgaGVpZ2h0PSIyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIyLjAyOTY4IC00MC4wOTAzIDI2IDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48IS0tR2VuZXJhdGVkIGJ5IElKU1ZHIChodHRwczovL2dpdGh1Yi5jb20vaWNvbmphci9JSlNWRyktLT48cGF0aCBkPSJNMjcuOTc0MywtMzYuMTI3MmMwLDAuNDQ2NDI4IC0wLjE1NjI1LDAuODI1ODkzIC0wLjQ2ODc1LDEuMTM4MzlsLTEyLjEyMDUsMTIuMTIwNWwtMi4yNzY3OSwyLjI3Njc5Yy0wLjMxMjUsMC4zMTI1IC0wLjY5MTk2NCwwLjQ2ODc1IC0xLjEzODM5LDAuNDY4NzVjLTAuNDQ2NDI4LDAgLTAuODI1ODkzLC0wLjE1NjI1IC0xLjEzODM5LC0wLjQ2ODc1bC0yLjI3Njc5LC0yLjI3Njc5bC02LjA2MDI3LC02LjA2MDI3Yy0wLjMxMjUsLTAuMzEyNSAtMC40Njg3NSwtMC42OTE5NjUgLTAuNDY4NzUsLTEuMTM4MzljMCwtMC40NDY0MjkgMC4xNTYyNSwtMC44MjU4OTMgMC40Njg3NSwtMS4xMzgzOWwyLjI3Njc5LC0yLjI3Njc5YzAuMzEyNSwtMC4zMTI1IDAuNjkxOTY1LC0wLjQ2ODc1IDEuMTM4MzksLTAuNDY4NzVjMC40NDY0MjksMCAwLjgyNTg5MywwLjE1NjI1IDEuMTM4MzksMC40Njg3NWw0LjkyMTg4LDQuOTM4NjJsMTAuOTgyMSwtMTAuOTk4OWMwLjMxMjUsLTAuMzEyNSAwLjY5MTk2NCwtMC40Njg3NSAxLjEzODM5LC0wLjQ2ODc1YzAuNDQ2NDI4LDAgMC44MjU4OTMsMC4xNTYyNSAxLjEzODM5LDAuNDY4NzVsMi4yNzY3OCwyLjI3Njc5YzAuMzEyNSwwLjMxMjUgMC40Njg3NSwwLjY5MTk2NCAwLjQ2ODc1LDEuMTM4MzlaIiB0cmFuc2Zvcm09InNjYWxlKDEuMDAxOTgpIiBmaWxsPSIjZmZmIj48L3BhdGg+PC9zdmc+");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
}
