.select-container .react-select__control {
  border-radius: 3px;
  border-color: var(--line-color);
  transition: border-color 300ms, background-color 300ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;

  &:hover {
    border-color: var(--line-color);
  }
}

.select-container  .react-select__value-container--has-value,
.select-container  .react-select__single-value {
  color: var(--text-color)
}

.select-container .react-select__control--is-focused {
  box-shadow: none;
  border-color: #0074d9;

  &:hover {
    border-color: #0074d9;
  }
}

.select-container input {
  height: initial;
}

.react-select__single-value {
  color: var(--text-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .flex {
    width: 100%;
  }
}

.select-container .react-select__placeholder {
  color: var(--placeholder-color);
}

.react-select__value-container {
  min-width: 200px;
}

.tools-sidebar .react-select__value-container {
  min-width: 120px;

  @include mq(114em) {
    min-width: 140px;
  }
}

.select-container-narrow .react-select__value-container {
  min-width: 0;
}

.select-container-narrow {
  min-width: 160px;
}

.select-container .react-select__multi-value {
  background-color: var(--tag-color);
}

.select-container-big {
  width: 100%;
}

.select-container .react-select__multi-value__label {
  text-overflow: initial;
  color: var(--text-color)
}


.select-container .react-select__menu {
  z-index: 10 !important;
  background-color: transparent;
}

.select-container .react-select__menu-list {
  background-color: var(--background-color);

  * {
    color: var(--text-color);
  }
}

.select-container .react-select__option {
  overflow-x: hidden;
  background-color: transparent;
}

.select-container .react-select__menu-list label {
  color: var(--text-color)
}

.select-container .react-select__input-container {
  color: var(--text-color)

}


.react-select__dropdown-indicator svg {
  fill: var(--text-color);
}
