/* -------------------------------------------------------------------
Base fields
------------------------------------------------------------------- */

.field {
  border: 1px solid var(--line-color)
}

.dcr-response .field {
  border: 0;
}

.field, textarea {
  background-color: transparent
}

.field-light {
  @extend .field;
}

.full-width {
  width: 100%;
}

/* -------------------------------------------------------------------
Focus color
------------------------------------------------------------------- */

.field-light:focus {
  border-color: var(--line-color);
}

/* -------------------------------------------------------------------
Error responses
------------------------------------------------------------------- */

label.is-error {
  color: $cta-danger;
}

label {
  color: inherit;
  line-height: 2;
}

[data-color-scheme="dark"] {
  input[type="time"],
  input[type="date"] {
    color-scheme: dark;
  }
}

input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"] {
  height: 38px;
}

input[type="text"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="date"],
textarea {
  color: var(--text-color);
  transition: border-color 300ms ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: var(--line-color);

  &::selection {
    background: #2f4461;
    color: rgb(255 255 255);
    text-shadow: none;
  }
}

/* Disable Webkit search decoration */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

/* File uploads */
input[type="file"]::file-selector-button {
  border: none;
  background-color: $primary-light;

  &:hover,
  &:active {
    background-color: darken($primary-light, 10%);
    color: white;
  }

  font-size: 0.75rem;

  @include mq(52em) {
    font-size: 0.75rem;
  }

  margin-right: 1rem;
  height: 28px;
  line-height: inherit;
  padding: 0 0.75rem;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

textarea.token-hidden-field {
  display: none;
}

.field-mono {
  font-family: var(--type-mono);
  font-weight: 400;
  font-size: 0.95rem;
  color: $primary;
  padding: 1.5rem;
  word-break: break-all;
}

.key {
  color: $key;
}

.string,
.value {
  color: $value;
}

.raw {
  color: var(--text-color);
}

/* Placeholders */
::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

/* Read only */

.field:read-only:not(select),
.field-light:read-only:not(select),
.field.is-read-only,
.is-read-only.field-light {
  background-color: var(--background-color);
}

.fieldset-server-response pre.field.field-dark {
  background-color: #272822;
  color: lighten($primary, 30%);
}

.fieldset-server-response pre .key {
  color: lighten($key, 20%);
}

.fieldset-server-response pre .value {
  color: lighten($value, 10%);
}

.fieldset-server-response pre {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Details */

details {
  cursor: pointer;
  user-select: none;

  summary {
    text-transform: uppercase;
    font-family: var(--type-mono);
    font-size: 0.75rem;
    font-weight: 500;
    outline: none;
  }
}

/* Fieldset with numbers */

fieldset {
  box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
  border-radius: 8px;
  padding: 1.25rem;
  position: relative;
  border: 1px solid var(--line-color);
  background-color: var(--well-color);
}

.fieldset-step {
  border: none;

  @media (width >= 60em) {
    margin-left: 45px;
  }

  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 17px;
    left: -35px;
    width: 10px;
    height: calc(100% + 17px);

    @media (width >= 60em) {
      border-left: 2px solid var(--line-color);
    }
  }

  &::after {
    content: attr(data-step);
    position: absolute;
    top: -17px;
    left: 90%;

    @media (width >= 60em) {
      top: 17px;
      left: calc(-35px - 17px);
    }

    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $number;
    color: white;
    font-size: 1.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fieldset-step-api {
  @media (width >= 60em) {
    margin-left: 0;
  }
}

.fieldset-folded .tools-form-token {
  display: none;
}

.fieldset-step:last-child::before {
  display: none;
}

.fieldset-server-response::after,
fieldset[data-urihelper]::after,
fieldset[data-token-purpose-selector]::after,
fieldset[data-decoded]::after,
fieldset[data-signature]::after{
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}


.fieldset-server-response {
  @extend .fieldset-step;

  &:not(.fieldset-dimmed) {
    cursor: pointer;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='100%25' height='100%25' stroke='white' fill='none'%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='14' d='M149.3 57H85.4c-7.84 0-14.2 6.36-14.2 14.2v113.6c0 7.84 6.36 14.2 14.2 14.2h85.2c7.84 0 14.2-6.36 14.2-14.2V92.5z'%3E%3C/path%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='14' d='M142.2 57v28.4c0 7.84 6.36 14.2 14.2 14.2h28.4M113.8 106.7H99.6M156.4 135.1H99.6M156.4 163.5H99.6'%3E%3C/path%3E%3C/svg%3E");
    background-color: $primary;
  }
}


fieldset[data-urihelper] {
  @extend .fieldset-step;

  color: white;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='100%25' height='100%25' stroke='white' fill='none'%3E%3Cpath fill='none' stroke-miterlimit='10' stroke-width='15.14' d='m207.86 66.81-14.27-14.27c-3.94-3.94-9.11-5.91-14.27-5.91s-10.33 1.97-14.27 5.91L78.4 139.18c-3.94 3.94-15.99 19.19-16 24.35l-10.09 44.55 44.54-10.09s20.42-12.06 24.36-16l86.65-86.64c7.88-7.88 7.88-20.66 0-28.54ZM161.52 77.46l19.66 19.66'%3E%3C/path%3E%3C/svg%3E");
    background-color: $primary;
  }
}

fieldset[data-token-purpose-selector] {
  @extend .fieldset-step;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='100%25' height='100%25' stroke='white' fill='none'%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='12' d='M111.28 51.29c4.25-17.53 29.19-17.53 33.45 0 2.24 9.24 11.54 14.91 20.78 12.68 1.73-.42 3.39-1.11 4.91-2.03 15.41-9.39 33.05 8.25 23.66 23.66-4.95 8.12-2.37 18.71 5.75 23.66 1.51.92 3.16 1.61 4.89 2.02 17.53 4.25 17.53 29.19 0 33.45-9.24 2.24-14.91 11.54-12.68 20.78.42 1.73 1.11 3.39 2.03 4.91 9.39 15.41-8.25 33.05-23.66 23.66-8.12-4.95-18.71-2.37-23.66 5.75-.92 1.51-1.61 3.16-2.02 4.89-4.25 17.53-29.19 17.53-33.45 0-2.24-9.24-11.54-14.91-20.78-12.68-1.73.42-3.39 1.11-4.91 2.03-15.41 9.39-33.05-8.25-23.66-23.66 4.95-8.12 2.37-18.71-5.75-23.66-1.51-.92-3.16-1.61-4.89-2.02-17.53-4.25-17.53-29.19 0-33.45 9.24-2.24 14.91-11.54 12.68-20.78-.42-1.73-1.11-3.39-2.03-4.91-9.39-15.41 8.25-33.05 23.66-23.66 9.98 6.07 22.92.7 25.68-10.63Z'%3E%3C/path%3E%3Ccircle cx='128' cy='128' r='29.95' fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3E%3C/circle%3E%3C/svg%3E");
    background-color: $primary;
  }
}

fieldset[data-decoded] {
  @extend .fieldset-step;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='100%25' height='100%25' stroke='white' fill='none'%3E%3Cpath fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='14' d='M99.53 53.49 25.02 128l74.51 74.51M156.47 202.51 230.98 128l-74.51-74.51'%3E%3C/path%3E%3C/svg%3E");
    background-color: $primary;
  }
}

fieldset[data-signature] {
  @extend .fieldset-step;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' width='100%25' height='100%25' stroke='white' fill='none'%3E%3Cpath fill='none' stroke-miterlimit='10' stroke-width='15.14' d='m207.86 66.81-14.27-14.27c-3.94-3.94-9.11-5.91-14.27-5.91s-10.33 1.97-14.27 5.91L78.4 139.18c-3.94 3.94-15.99 19.19-16 24.35l-10.09 44.55 44.54-10.09s20.42-12.06 24.36-16l86.65-86.64c7.88-7.88 7.88-20.66 0-28.54ZM161.52 77.46l19.66 19.66'%3E%3C/path%3E%3C/svg%3E");
    background-color: $primary;
  }
}


/* Dimmed (inactive) */
.fieldset-dimmed {
  opacity: .4;
  filter: grayscale(1);

  h3 {
    color: var(--text-color);
  }
}

span.input-invalid {
  background-color: darkred;
  color: white !important;
}

.tools-input ::selection {
  background: #f2f2f2;
  color: initial;
}

::selection {
  background: #f2f2f2;
  color: initial;
}

/* Share URL, hidden */

#jwturl {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.field-group {
  position: relative;
  width: 100%;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  background-color: #fff;
  font-weight: 300;
  border-color: #0074d9;
  border-width: 0 1px 1px;
  border-style: solid;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 195px;
  overflow-y: scroll;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f2f3f6;
}

.or {
  line-height: 0.5;
  text-align: center;
}

.or span {
  display: inline-block;
  position: relative;
}

.or span::before,
.or span::after {
  content: "";
  position: absolute;
  height: 0.5rem;
  border-top: 1px solid #ccc;
  top: 0.25rem;
  width: 100px;
}

.or span::before {
  right: 100%;
  margin-right: 1rem;
}

.or span::after {
  left: 100%;
  margin-left: 1rem;
}
