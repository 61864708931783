@use "sass:math";
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Grid */

.container {
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
}
.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: (math.div(1,12) * 100%);
}

.col-2 {
  width: (math.div(2,12) * 100%);
}

.col-3 {
  width: (math.div(3,12) * 100%);
}

.col-4 {
  width: (math.div(4,12) * 100%);
}

.col-5 {
  width: (math.div(5,12) * 100%);
}

.col-6 {
  width: (math.div(6,12) * 100%);
}

.col-7 {
  width: (math.div(7,12) * 100%);
}

.col-8 {
  width: (math.div(8,12) * 100%);
}

.col-9 {
  width: (math.div(9,12) * 100%);
}

.col-10 {
  width: (math.div(10,12) * 100%);
}

.col-11 {
  width: (math.div(11,12) * 100%);
}

.col-12 {
  width: 100%;
}
@media #{$breakpoint-sm} {

  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: (math.div(1,12) * 100%);
  }

  .sm-col-2 {
    width: (math.div(2,12) * 100%);
  }

  .sm-col-3 {
    width: (math.div(3,12) * 100%);
  }

  .sm-col-4 {
    width: (math.div(4,12) * 100%);
  }

  .sm-col-5 {
    width: (math.div(5,12) * 100%);
  }

  .sm-col-6 {
    width: (math.div(6,12) * 100%);
  }

  .sm-col-7 {
    width: (math.div(7,12) * 100%);
  }

  .sm-col-8 {
    width: (math.div(8,12) * 100%);
  }

  .sm-col-9 {
    width: (math.div(9,12) * 100%);
  }

  .sm-col-10 {
    width: (math.div(10,12) * 100%);
  }

  .sm-col-11 {
    width: (math.div(11,12) * 100%);
  }

  .sm-col-12 {
    width: 100%;
  }

}
@media #{$breakpoint-md} {

  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: (math.div(1,12) * 100%);
  }

  .md-col-2 {
    width: (math.div(2,12) * 100%);
  }

  .md-col-3 {
    width: (math.div(3,12) * 100%);
  }

  .md-col-4 {
    width: (math.div(4,12) * 100%);
  }

  .md-col-5 {
    width: (math.div(5,12) * 100%);
  }

  .md-col-6 {
    width: (math.div(6,12) * 100%);
  }

  .md-col-7 {
    width: (math.div(7,12) * 100%);
  }

  .md-col-8 {
    width: (math.div(8,12) * 100%);
  }

  .md-col-9 {
    width: (math.div(9,12) * 100%);
  }

  .md-col-10 {
    width: (math.div(10,12) * 100%);
  }

  .md-col-11 {
    width: (math.div(11,12) * 100%);
  }

  .md-col-12 {
    width: 100%;
  }

}
@media #{$breakpoint-lg} {

  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: (math.div(1,12) * 100%);
  }

  .lg-col-2 {
    width: (math.div(2,12) * 100%);
  }

  .lg-col-3 {
    width: (math.div(3,12) * 100%);
  }

  .lg-col-4 {
    width: (math.div(4,12) * 100%);
  }

  .lg-col-5 {
    width: (math.div(5,12) * 100%);
  }

  .lg-col-6 {
    width: (math.div(6,12) * 100%);
  }

  .lg-col-7 {
    width: (math.div(7,12) * 100%);
  }

  .lg-col-8 {
    width: (math.div(8,12) * 100%);
  }

  .lg-col-9 {
    width: (math.div(9,12) * 100%);
  }

  .lg-col-10 {
    width: (math.div(10,12) * 100%);
  }

  .lg-col-11 {
    width: (math.div(11,12) * 100%);
  }

  .lg-col-12 {
    width: 100%;
  }

}

/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/