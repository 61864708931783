/* Box header */

.tools-box-header {
    font-size: 0.9rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
}

.tools-sidebar .tools-box-header {
    padding: .5rem;
}

fieldset .modal .tools-box-header {
    display: inherit;
}

.tools-box-header-token label:first-of-type {
    display: none;

    @include mq(84em) {
        display: initial;
    }
}

.tools-sidebar .tools-input.tools-input-readonly {
    border-top-right-radius: 0;
}

/* Dropdown inside */
.tools-box-header .react-select__control {
    background-color: transparent;
    border: 1px solid darken($primary, 5%);

    &.react-select__control--is-focused,
    &:hover {
        background-color: var(--background-color);
        border: 1px solid var(--line-color);

        .react-select__single-value {
            color: var(--text-color);
        }
    }

    &.react-select__control--is-focused .react-select__single-value {
        color: var(--text-color);
    }
}

.tools-box-header .react-select__single-value {
    color: var(--text-color);
}

.tools-box-header .react-select__placeholder {
    color: var(--text-color);
}

.tools-sidebar-box-signature .tools-box-header,
.tools-form-token .tools-box-header {
    background-color: var(--background-color);
    border-top: 1px solid var(--line-color);
    border-left: 1px solid var(--line-color);
    border-right: 1px solid var(--line-color);
    border-bottom: 1px solid var(--line-color);
    padding: 0.45rem 0.5rem;
}

.tools-box-header-title {
    text-transform: uppercase;
    font-family: var(--type-mono);
    font-weight: 500;
    font-size: 0.75rem;

}

.tools-box-header .button {
    margin-bottom: 0;
}

.tools-box-header .button-settings:hover {
    background-color: white;
}

/* Box header title */

input[type='text'].tools-box-header-title {
    padding: 0.25rem 1rem;
    background-color: transparent;
    color: var(--text-color);
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    height: auto;
    width: auto;
    display: inline-block;
    border: none;
    max-width: 260px;
}

a.tools-box-header-title-edit,
button.tools-box-header-title-edit {
    border: none;
    box-shadow: none;
    color: #ccc;
    display: none;

    .icon {
        font-size: 1rem;
    }

    &:hover {
        border: none;
        box-shadow: none;

        .icon {
            color: $primary;
        }
    }

    &:focus {
        border: none;
        box-shadow: none;
    }
}

/* Fold content inside */
.tools-sidebar-box.folded .tools-input,
.tools-form-token.folded + .tools-input {
    display: none;
}


input[type='text'].tools-box-header-title.active {
    background-color: white;
    color: initial;
    pointer-events: initial;
}


.tools-box-header-title .button:hover {
    box-shadow: none;
}

.tools-box-header-title button.button {
    box-shadow: none;

    &:hover {
        box-shadow: none;
        transform: none;
    }
}

/* Status */
.tools-box-header-title-status {
    position: relative;
    display: none;
}

.tools-box-header-title-status-indicator {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tools-box-header-title-status-indicator-error {
    background-color: $cta-danger;
}

.tools-box-header-title-status-indicator-success {
    background-color: $cta-success;
}
