
// Converted Variables

$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$body-font-size: 100% !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$heading-margin-top: 1em !default;
$heading-margin-bottom: .5em !default;
$paragraph-margin-top: 0 !default;
$paragraph-margin-bottom: $space-2 !default;
$list-margin-top: 0 !default;
$list-margin-bottom: $space-2 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$pre-font-size: inherit !default;
$pre-margin-top: 0 !default;
$pre-margin-bottom: $space-2 !default;
$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Base Typography */

body {
  font-family: $font-family;
  line-height: $line-height;
  font-size: $body-font-size;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  margin-top: $heading-margin-top;
  margin-bottom: $heading-margin-bottom;
}

p {
  margin-top: $paragraph-margin-top;
  margin-bottom: $paragraph-margin-bottom;
}

dl, ol, ul {
  margin-top: $list-margin-top;
  margin-bottom: $list-margin-bottom;
}

pre, code, samp {
  font-family: $monospace-font-family;
  font-size: $pre-font-size;
}

pre {
  margin-top: $pre-margin-top;
  margin-bottom: $pre-margin-bottom;
  overflow-x: scroll;
}

h1 { font-size: $h1 }
h2 { font-size: $h2 }
h3 { font-size: $h3 }
h4 { font-size: $h4 }
h5 { font-size: $h5 }
h6 { font-size: $h6 }

/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/