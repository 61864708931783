/* Header */
@include mq(64em) {
    #content > div {
        position: fixed;
        top: 0;
        z-index: 3;
        width: 100%;
    }

    html.standalone #content > div {
        position: fixed;
        top: $electron_toolbar_height;
        z-index: 3;
        width: 100%;
    }
}

/* Main wrapper */
.tools-main {
    overflow-x: hidden;

    @include mq(52em) {
        height: 100vh;
    }
}

/*  History */
.tools-history {
    height: auto;
    position: relative;
    width: calc(100% - var(--activity-bar-width));
    left: var(--activity-bar-width);

    @include mq(52em) {
        width: calc(var(--history-sidebar-width) - var(--activity-bar-width));
        position: absolute;
        top: $header_height;
        overflow-y: auto;
        height: calc(100vh - #{$header_height})
    }
}

/* Main working area */
.tools-form {
    height: auto;
    position: relative;
    overflow-y: auto;
    left: var(--activity-bar-width);
    width: calc(100% - var(--activity-bar-width));
    background-color: var(--page-color);

    @include mq(52em)

 {
        left: unset;
        width: unset;
    }
}

/* Sidebar */
.tools-sidebar {
    height: calc(100vh - #{$header_height});
    position: static;
    z-index: -1;

    @include mq(52em) {
        overflow-y: auto;
    }
}

/* Resizable columns/panels */
.split {
    position: absolute;
    top: $header_height;
    z-index: 0;

    @include mq(52em) {
        display: flex;
        width: calc(100vw - calc(var(--history-sidebar-width)));
        height: calc(100vh - $header_height);
        left: var(--history-sidebar-width);

    }
}

.split > section,
.split > aside,
.split > div:not(.gutter) {
    @include mq(52em) {
        height: calc(100vh - #{$header_height})
    }
}

.tools-form-content {
    overflow-y: auto;

    @include mq(52em) {
        height: calc(100vh - #{$header_height} - 45px)
    }
}

/* The gutter */
.gutter {
    background-color: var(--background-color);
    background-repeat: no-repeat;
    background-position: 50%;
}

/* The dotted dragging handle */
.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: ew-resize;

    &:hover {
        background-color: var(--line-color);
    }
}
