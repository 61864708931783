.tools-input {
  width: 100%;
  max-width: 100%;
  appearance: none;
  color: lightgoldenrodyellow;
  padding: 1.5rem;
  border-radius: 4px 0 4px 4px;
  border: none;
  font-family: var(--type-mono);
  font-size: 1rem;
  position: relative;
}

.tools-form-content .tools-input {
  min-width: 100%;
}


/* Signature */

.tools-input-signature {
  font-size: 0.75rem;
  color: var(--text-color);
}

.tools-input-signature::selection {
  background-color: lighten($primary, 20%);
  color: white;
}

.tools-input.tools-input-no-shadow {
  box-shadow: none;
}

.tools-input.tools-input-readonly {
  padding: 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #f2f2f2;
  font-size: 0.85rem;
}

pre.tools-input {
  word-break: break-all;
  white-space: pre-wrap;
}

.tools-input:focus {
  outline: none;
  box-shadow: none;

  &::before {
    display: block;
    content: 'Editing';
    color: orange;
    font-family: var(--type-mono);
    font-size: 1.15rem;
  }
}

.tools-input-jwt::selection {
  background-color: lighten($primary, 20%);
  color: white;
}


.tools-sidebar-box-content-area {
  background-color: var(--background-color);
  border: 1px solid var(--line-color);
  border-radius: 4px;
}

.tools-sidebar-box-content-area-header {
  display: flex;
  border-bottom: 1px solid var(--line-color);
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding: .5rem;
}

.label-key {
  font-family: var(--type-mono);
  font-size: 0.75rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  display: inline-block;
  top: 1px;
  color: var(--text-color)

}

.label-key-switch {
  padding: 0;
  border: none;

}

pre,
code,
samp {
  font-family: var(--type-mono);
  font-size: inherit;
}

@media (width >= 40em) {
  pre {
    max-width: none;
  }
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
  max-width: 90vw;
  margin-bottom: 0;
}

pre code {
  white-space: pre-wrap;
  overflow-x: auto;
  word-break: break-all;
}

.inline-code {
  font-family: var(--type-mono);
  color: var(--text-color);
  border: 1px solid rgb(0 0 0 / 5%);
  background-color: var(--inner-background-color);
  padding: 0 4px;
  font-size: 0.9em;
  overflow-wrap: anywhere;
  border-radius: 4px;
}

.tools-input div.line {
  padding-left: 3em;
  text-indent: -3em;

  .line {
    padding-left: 0;
    text-indent: -3em;
  }
}

.json {
  .jwt-dot {
    color: #474747;
  }

  .jwt-header {
    color: $key;
  }

  .jwt-payload {
    color: $value;
  }

  .jwt-signature {
    color: $number;
  }

  .jwt-disclosure {
    color: $disclosure;
  }
}

[data-color-scheme="dark"] {
  .json {
    .jwt-dot {
      color: #fff;
    }

    .jwt-header {
      color: lighten($key, 10%);
    }

    .jwt-payload {
      color: lighten($value, 10%);
    }

    .jwt-signature {
      color: lighten($number, 10%);
    }

    .jwt-disclosure {
      color: lighten($disclosure, 10%);
    }
  }
}

/* ACE Editor */

.ace-monokai {
  background-color: transparent;
}

.ace-editor-wrapper {
  background-color: #1d2028;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid var(--line-color);
  border-right: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
}

.ace-monokai .ace_gutter {
  background-color: #1d2028;
}

.tools-form .ace_editor {
  font-family: var(--type-mono);
  font-size: 0.85rem !important;
}

.ace_jwt-header {
  color: lighten($key, 10%);
}

.ace_jwt-payload {
  color: lighten($value, 10%);
}

.ace_jwt-signature {
  color: lighten($number, 10%);
}

.ace_jwt-disclosure {
  color: #67ecff;
}

.ace_jwt-error {
  color: lighten($cta-danger, 25%);
}

.fieldset-connector {
  display: none;
  width: 32px;
  height: 1px;
  position: absolute;
  top: 1.75rem;
  left: 100%;
  background-color: #666;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


@media (width >= 80em) {
  .tools-form-create-jwt fieldset {
    position: relative;
  }

  .tools-form-create-jwt fieldset:first-of-type .fieldset-connector,
  .tools-form-create-jwt fieldset:nth-of-type(2) .fieldset-connector,
  .tools-form-create-jwt fieldset:nth-of-type(3) .fieldset-connector {
    display: block;
  }
}
