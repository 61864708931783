.modal-environments .modal-panel main {
    background-color: var(--background-color);
    min-height: calc(80vh - 60px - 65px);
}

.modal-environments .modal-panel-section  {
    padding-bottom: 2rem;
}

.modal-environments .modal-panel-actions {
    margin-left: auto;
}

.modal-main.modal-start,
.modal-main.modal-environments {
    --inset: 20px;
    --header-stripe-height: 41px;
    --footer-height: 40px;

    top: calc(var(--header-stripe-height) + var(--inset));
    max-height: calc(100vh - var(--header-stripe-height) - var(--footer-height) - calc(var(--inset) * 2));
}

.modal-main-content.modal-environments {
    padding: 0;
}

.modal-environments .tab-environments {
    display: flex;
    flex: 0 0 250px;
    flex-direction: column;
    position: sticky;
    top: 60px;
    height: 100%;
    width: 100%;
    z-index: 11;
}

.modal-environments .tab-environments ul {
    width: 250px;
}

.modal-environments .tab-environments li {
    position: relative;
}

.modal-environments .tab-environments a {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 0.85rem;

    &:hover {
        color: white;
        text-decoration: none;
        background-color: darken($primary, 30%);
    }

    &:focus {
        background-color: darken($primary, 35%);
        outline: none;
    }

    &.active,
    &:active {
        background-color: darken($primary, 10%);
        color: white;
    }
}

.tab-content {
    flex: auto;
}
