/* -------------------------------------------------------------------
Button base
------------------------------------------------------------------- */

button,
a.button,
.button {
  -webkit-font-smoothing: antialiased;
  transition: background-color 200ms ease, box-shadow 200ms ease;
  border-radius: 4px;
  font-weight: 400;
  background-color: transparent;
  color: white;
  line-height: ($button-line-height * 1.25);
  padding: ($button-padding-y * 1.25) ($button-padding-x * 1.25);
  font-family: var(--type-bold);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  letter-spacing: 0.075rem;
  white-space: nowrap;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;

  &:hover {
    box-shadow: 0 3px 12px rgb(0 0 0 / 23%), 0 3px 12px rgb(0 0 0 / 16%);
  }

  &:hover,
  &:active {
    color: white;
  }


  @include mq(52em) {
    margin-bottom: 0;
  }

  .icon {
    line-height: 0;
  }

  &:has(span) {
    gap: .5rem;
  }
}


/* -------------------------------------------------------------------
Colors
------------------------------------------------------------------- */

a.button-primary,
.button-primary {
  background: $primary-light;

  &:hover,
  &:active {
    background: darken($primary-light, 10%);
    color: white;
  }
}

a.button-signicat,
.button-signicat {
  background: white;
  color: $signicat;
  border-color: $signicat;

  &:active {
    background: white;
    color: $signicat;
    border-color: $signicat;
  }

  &:hover {
    background: $signicat;
    color: white;
  }

  svg.inlineicon {
    max-height: 25px;
  }
}

a.button-primary-dark,
.button-primary-dark {
  background: darken($primary, 20%);
  color: white;

  &:hover,
  &:active {
    background: darken($primary, 30%);
    color: white;
  }
}

a.button-white-outline,
.button-white-outline {
  background-color: none;
  border: 0.075rem solid white;
  color: white;

  &:hover,
  &:active {
    border: 0.075rem solid white;
    background-color: white;
    color: $primary;
  }
}

a.button-primary-outline,
.button-primary-outline {
  background: none;
  color: $primary-light;
  border: 0.075rem solid $primary-light;
  box-shadow: none;

  &:hover,
  &:active {
    box-shadow: none;
    background: $primary-light;
    color: white;

    .icon {
      color: white;
    }
  }
}

[data-color-scheme="dark"] a.button-primary-outline,
[data-color-scheme="dark"] .button-primary-outline {
  color: white;

  &:hover,
  &:active {
    background: $primary-light;
    color: white;

    .icon {
      color: white;
    }
  }
}


a.button-light,
.button-light {
  border: 0.075rem solid var(--line-color);
  box-shadow: none;
  color: $primary-light;

  &:hover {
    background-image: none;
    text-decoration: none;
    transform: none;
    box-shadow: none;
    color: $primary-light;
  }

  &:active,
  &:focus {
    background-image: none;
    text-decoration: none;
    transform: none;
  }
}

a.button-icon,
.button-icon {
  svg {
    line-height: 0;
  }
}

/* -------------------------------------------------------------------
Call to action
------------------------------------------------------------------- */

a.button-warning,
.button-warning {
  background-color: darken($cta-warning, 25%);

  &:hover,
  &:active {
    background: darken($cta-warning, 35%);
    color: white;
  }
}

a.button-danger,
.button-danger {
  background-color: lighten($cta-danger, 15%);

  &:hover,
  &:active {
    background: lighten($cta-danger, 5%);
    color: white;
  }
}

a.button-success,
.button-success {
  background-color: lighten($cta-success, 6%);

  &:hover,
  &:active {
    background: lighten($cta-success, 0%);
    color: white;
  }
}

a.button-info,
.button-info {
  background-color: darken($cta-info, 20%);

  &:hover,
  &:active {
    background: darken($cta-info, 30%);
    color: white;
  }
}

a.button-info-outline,
.button-info-outline {
  background: none;
  border: 0.075rem solid darken($cta-info, 20%);
  color: darken($cta-info, 20%);

  &:hover,
  &:active {
    box-shadow: none;
    background: darken($cta-info, 20%);
    color: white;
  }
}

a.button-warning-outline,
.button-warning-outline {
  background: none;
  border: 0.075rem solid darken($cta-warning, 45%);
  color: darken($cta-warning, 45%);

  &:hover,
  &:active {
    box-shadow: none;
    background: darken($cta-warning, 45%);
    color: white;
  }
}

a.button-danger-outline,
.button-danger-outline {
  background: none;
  border: 0.075rem solid $cta-danger;
  color: $cta-danger;
  box-shadow: none;

  &:hover,
  &:active {
    box-shadow: none;
    background: $cta-danger;
    color: white;
  }
}

a.button-success-outline,
.button-success-outline {
  background: none;
  border: 0.075rem solid $cta-success;
  color: $cta-success;
  box-shadow: none;

  &:hover,
  &:active {
    box-shadow: none;
    background: $cta-success;
    color: white;
  }
}

/* -------------------------------------------------------------------
Social
------------------------------------------------------------------- */

button[type="submit"].button-social,
button.button-social,
a.button-social,
.button-social {
  text-align: left;
  padding-left: 80px;
  margin-top: 0;

  .icon {
    font-size: 1.95rem;
    width: 65px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

a.button-github,
.button-github {
  background-color: $authenticator-github-color;

  .icon {
    background-color: darken($authenticator-github-color, 10%);
  }

  &:hover {
    background-color: darken($authenticator-github-color, 10%);
  }
}

/* -------------------------------------------------------------------
Sizes
------------------------------------------------------------------- */

a.button-tiny,
.button-tiny {
  font-size: 0.75rem;
  height: 28px;

  @include mq(52em) {
    font-size: 0.75rem;
  }

  line-height: inherit;
  padding: 0 0.75rem;
}

a.button-small,
.button-small {
  font-size: 0.75rem;
  height: 32px;

  @include mq(52em) {
    font-size: 0.8rem;
  }

  padding: 0 $button-padding-x * 1.25 0 $button-padding-x * 1.25;
}

a.button-medium,
.button-medium {
  font-size: 1rem;
  height: 44px;

  @include mq(52em) {
    font-size: 1.15rem;
  }

  padding: 0 $button-padding-x * 1.5 0 $button-padding-x * 1.5;
}

a.button-large,
.button-large {
  font-size: 1.15rem;
  height: 50px;

  @include mq(52em) {
    font-size: 1.35rem;
  }

  padding: 0 $button-padding-x * 2.25 0 $button-padding-x * 2.25;
}

/* -------------------------------------------------------------------
Variants
------------------------------------------------------------------- */

a.button-transparent,
.button-transparent {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: unset;

  &:hover {
    background-image: none;
    background-color: transparent;
    text-decoration: none;
    transform: none;
    box-shadow: none;
    color: unset;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

a.button-input,
.button-input {
  height: 38px;
  display: flex;
}

a.button-fullwidth,
.button-fullwidth {
  width: 100%;
  display: flex;
  justify-content: center;
}

a.button-center,
.button-center {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

a.button-presskit,
.button-presskit {
  border-radius: 30em;
  background-color: transparent;
  border: 1px solid #ccc;
  color: $primary;

  &:hover {
    color: white;
    background-color: $primary;
    transform: scale(1.05);
  }
}

a.button-uppercase,
.button-uppercase {
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

/* When placing ex right to a input text,email,search */

a.button-inputgroup,
.button-inputgroup {
  border-radius: 0 0.075rem 0.075rem 0;
  padding: inherit;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: $primary;
}

/* Start flow or add JWT */

a.button-run,
.button-run {
  text-align: center;

  &:not([disabled]) {
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: 3;
  }
}

@keyframes color {
  0% {
    background-color: lighten($cta-success, 6%);
  }

  50% {
    background-color: $key;
  }

  100% {
    background-color: lighten($cta-success, 6%);
  }
}

/* Flex button */
a.button-flex,
.button-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Button link */

a.button-link,
.button-link {
  box-shadow: none;
  border: none;
  margin-bottom: 0;
  color: $primary;
  font-family: var(--type-bold);

  &:hover {
    background-image: none;
    color: $primary;
    text-decoration: none;
    transform: none;
    box-shadow: none;
  }

  &:active,
  &:focus {
    background-color: transparent;
    background-image: none;
    color: $primary;
    text-decoration: none;
    transform: none;
  }

  .icon-cta-success {
    color: $cta-success;
  }

  .icon-cta-danger {
    color: $cta-danger;
  }
}

/* Icon only */
a.button-icon-only,
.button-icon-only {
  padding-left: .5rem;
  padding-right: .5rem;
}

button[disabled],
button:disabled,
a.button-disabled,
.button-disabled {
  box-shadow: none;
  user-select: none;
  pointer-events: none;
  filter: grayscale(100%);

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

/* Loading */

a.button-loading,
.button-loading {
  position: relative;

  span {
    transition: opacity 100ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

a.button-loading-active,
.button-loading-active {
  span {
    opacity: 0;
  }
}

a.button-loading-active::after,
.button-loading-active::after {
  animation: loading 300ms infinite linear;
  border: 0.15rem solid;
  border-radius: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  position: absolute;
  height: 16px;
  width: 16px;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.show-password {
  border: none;
  position: absolute;
  right: 1px;
  top: 1px;
  margin: 0;
  padding: 0 0.5rem;
  height: calc(100% - 2px);
  border-radius: 0;
  box-shadow: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: unset;
  background-color: var(--background-color);

  &:hover {
    transform: none;
    box-shadow: none;
    color: initial
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

button.show-password:active {
  transform: none;
}

.button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

button.field-clear {
  opacity: 0;
  transition: none;
  color: #ccc;

  &.active {
    opacity: 1;
    color: #666;
  }

  .icon {
    transition: none;
    font-size: 0.85rem;
  }
}

/* Fold */
.button.button-fold {
  position: relative;
  box-shadow: none;
  border: none;
}

/* Button group */

.button-group {
  display: flex;
}

.button-group .button {
  border-radius: 0;
}

.button-group .button:first-of-type {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.button-group .button:not(:last-of-type, :nth-last-child(2)) {
  border-right: none;
}

.button-group .button:last-of-type {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-left: none;
}

.button-group .button:active,
.button-group .button:focus {
  outline: none;
  box-shadow: none;
}

.button-flat {
  box-shadow: none;
}

.button-flat:hover::before,
.button-flat:focus::before {
  opacity: 0;
}

.radio-btn-group div:nth-child(1) .radio-style-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.radio-btn-group div:nth-child(2) .radio-style-button {
  border-radius: 0;
}

.radio-btn-group div:nth-child(3) .radio-style-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.radio-style-button {
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
  cursor: pointer;
  color: var(--text-color);
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 1rem 0.25rem 0.75rem;
  position: relative;
  transition: 150ms ease all;
  width: 100%;
}

.radio-style-button::before {
  background-color: #f2f2f2;
  border-radius: 50%;
  content: "";
  height: 20px;
  left: 20px;
  top: calc(50% - 15px);
  transition: 0.3s ease background-color;
  width: 20px;
  margin-right: 0.5rem;
}

.radio-style-button-checked::before {
  background-color: #626c87;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNiIgaGVpZ2h0PSIyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIyLjAyOTY4IC00MC4wOTAzIDI2IDIwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48IS0tR2VuZXJhdGVkIGJ5IElKU1ZHIChodHRwczovL2dpdGh1Yi5jb20vaWNvbmphci9JSlNWRyktLT48cGF0aCBkPSJNMjcuOTc0MywtMzYuMTI3MmMwLDAuNDQ2NDI4IC0wLjE1NjI1LDAuODI1ODkzIC0wLjQ2ODc1LDEuMTM4MzlsLTEyLjEyMDUsMTIuMTIwNWwtMi4yNzY3OSwyLjI3Njc5Yy0wLjMxMjUsMC4zMTI1IC0wLjY5MTk2NCwwLjQ2ODc1IC0xLjEzODM5LDAuNDY4NzVjLTAuNDQ2NDI4LDAgLTAuODI1ODkzLC0wLjE1NjI1IC0xLjEzODM5LC0wLjQ2ODc1bC0yLjI3Njc5LC0yLjI3Njc5bC02LjA2MDI3LC02LjA2MDI3Yy0wLjMxMjUsLTAuMzEyNSAtMC40Njg3NSwtMC42OTE5NjUgLTAuNDY4NzUsLTEuMTM4MzljMCwtMC40NDY0MjkgMC4xNTYyNSwtMC44MjU4OTMgMC40Njg3NSwtMS4xMzgzOWwyLjI3Njc5LC0yLjI3Njc5YzAuMzEyNSwtMC4zMTI1IDAuNjkxOTY1LC0wLjQ2ODc1IDEuMTM4MzksLTAuNDY4NzVjMC40NDY0MjksMCAwLjgyNTg5MywwLjE1NjI1IDEuMTM4MzksMC40Njg3NWw0LjkyMTg4LDQuOTM4NjJsMTAuOTgyMSwtMTAuOTk4OWMwLjMxMjUsLTAuMzEyNSAwLjY5MTk2NCwtMC40Njg3NSAxLjEzODM5LC0wLjQ2ODc1YzAuNDQ2NDI4LDAgMC44MjU4OTMsMC4xNTYyNSAxLjEzODM5LDAuNDY4NzVsMi4yNzY3OCwyLjI3Njc5YzAuMzEyNSwwLjMxMjUgMC40Njg3NSwwLjY5MTk2NCAwLjQ2ODc1LDEuMTM4MzlaIiB0cmFuc2Zvcm09InNjYWxlKDEuMDAxOTgpIiBmaWxsPSIjZmZmIj48L3BhdGg+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}
