.empty-state {
    align-items: center;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: calc(100vh - 45px);
}

.empty-state-icon {
    color: #ececec;
    margin: 1rem auto;
    width: 100px;
}

.tools-form .empty-state-icon {
    color: rgb(220 220 220);
}
