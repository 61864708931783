[role="tablist"] {
    border-bottom: 1px solid #e8e8e8;
}

[role="tablist"] button {
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: $primary;
    border-bottom: 2px solid transparent;
    transition: border-color 400ms ease-in-out;
    cursor: pointer;
    font-size: 1rem;
    font-family: var(--type-regular);
    letter-spacing: unset;


    &.active {
        border-bottom: 2px solid $primary;
    }
}
