.activity-bar {
  background-color: darken($primary, 36%);
  border-bottom: 1px darken($primary, 36%);
  width: var(--activity-bar-width);
  position: absolute;
  left: 0;
  top: calc(#{$header_height});
  z-index: 2;
  border-right: 1px solid darken($primary, 18%);
  height: 100vh;
  overflow: hidden auto;

  @include mq(40em) {
    height: calc(100% - #{$header_height});
  }

  /* Custom minimal scrollbar */



  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }


}

.activity-bar nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.activity-bar button.activity-bar-button {
  position: relative;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--activity-bar-width) - 1px);
  height: var(--activity-bar-width);
  color: white;
  padding: 0;

  &:hover {
    * {
      stroke: $bright;
    }
  }
}

.activity-bar nav button i {
  font-size: 1.25rem;
}

.activity-bar [data-tooltip]::after {
  border: 1px solid $bright;
  background-color: $bright;
  font-family: Roboto-Medium, sans-serif;
  letter-spacing: 0.075rem;
  top: calc(var(--activity-bar-tooltip-position-top) + 2rem);
  position: fixed;
  right: unset;
  left: calc(var(--activity-bar-width) + 0.5rem);
}

.activity-bar [data-tooltip]::before {
  display: none;
}

.activity-bar .environment-initials[data-tooltip]::after {
  top: calc(var(--activity-bar-tooltip-position-top) + 1.5em);
}
