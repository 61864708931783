.new-environment {
  line-height: 100%;
  background: #545864;
  cursor: pointer;
}

.new-environment,
.environment-initials {
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--activity-bar-width) - 20px);
  height: calc(var(--activity-bar-width) - 20px);
  color: white;
  padding: 0;
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
  font-family: Roboto-Medium, sans-serif;
  text-transform: uppercase;
  box-shadow: inset 0 -3px 0 0 rgb(0 0 0 / 40%);
  user-select: none;

  &:not(:last-child) {
    margin-top: 16px;
  }

  &:hover {
    box-shadow: 0 0 0 2px rgb(255 255 255 / 50%);
  }
}

.environment-initials-selected {
  box-shadow: 0 0 0 2px rgb(255 255 255 / 100%);
}

/* Context menu */
.activity-bar .context-dropdown-menu {
  z-index: 2000;
  position: fixed;
  top: 100px;
  left: calc(var(--activity-bar-width) + 0.5rem);
  right: unset;
  width: max-content;
}

.activity-bar .context-dropdown-menu nav {
  display: unset;
}

/* Workspaces menu */
.tools-history-workspace-menu {
  border-bottom: 1px solid darken($primary, 18%);
}

.tools-history-workspace-menu .icon {
  transition: all 200ms ease;

  &.open {
    transform: rotate(180deg);
  }
}
