.tools-history {
  background-color: darken($primary, 28%);

  a {
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
  }
}


.tools-history-current {
  background-color: darken($primary, 10%);


}

.tools-history li {
  color: #ccc;
  white-space: nowrap;
  position: relative;
  background-color: darken($primary, 28%);

  &.tools-history-current,
  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary, 30%);

    a {
      color: white;
      text-decoration: none;
    }

    .tools-history-edit {
      opacity: 1;

      .icon {
        color: white;
      }
    }
  }

  *:not(input),
  *:not(input)::after,
  *:not(input)::before {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
  }
}

/* Entry name */

.tools-history-entry {
  display: inline-block;
  height: 30px;
  padding: 0.2rem;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  font-size: 0.85rem;
}

/* Edit input */

.tab-environments input[type="text"].field,
.tools-history-group input[type="text"].field,
.tools-history .history-list input[type="text"].field {
  height: 30px;
  padding: 0.2rem;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  font-size: 0.85rem;

  &::selection {
    background-color: $bright;
  }
}

.tab-environments input[type="text"].field {
  padding: 0;
}

/* Save */

.tools-history-field-wrapper {
  position: relative;
  display: inline-block;
}

.tools-history-field-save {
  right: 0;
  top: 0;
  position: absolute;
}

.tools-history .button-link[title="Start Flow"] {
  border: 1px solid $primary;
  color: $primary;

  &:hover {
    color: white;
  }
}

/* Collapse */
.tools-history-collapse {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  position: absolute;
  padding: 0;
  top: 1.15rem;
  right: 4px;
  color: white;

  &:active {
    background-color: darken($primary, 5%);
    color: white;
  }

  transition: background-color 200ms;
  cursor: pointer;
  display: none;

  @media (width >= 60em) {
    display: flex;
  }

  &:hover {
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
    color: white;
  }
}

.tools-history-collapse .ion-android-arrow-back {
  transition: transform 300ms;
  transform-origin: 50% 50%;
  transition-delay: 300ms;
  line-height: 0;

  &.collapsed {
    transform: scaleX(-1);
  }
}

/* History items menu */
.tools-history-menu-button {
  display: block;

  @include mq(64em) {
    display: none;
  }
}

.tools-history-menu-label {
  display: inline-flex;

  @include mq(64em) {
    display: none;
  }
}

/* Show small empty message if history-list is empty if no groups or collection added to a workspace */
ul.history-list:empty::after {
  content: attr(data-empty-message);
  line-height: 1.2;
  color: #626c87;
  font-size: 0.85rem;
  padding: 1rem;
  display: block;
}

.workspace-menu-name {
  padding: 1rem;
  align-items: center;
  color: white;
  cursor: pointer;
  line-height: 200%;
}

.tools-history-SIGNICAT {
  background-color: $signicat;

  a,
  .tools-history-group,
  .tools-history-header .button {
    color: white;
  }

  li {
    background-color: $signicat;

    a {
      background-color: transparent;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $signicat-darken;

      a {
        color: $white;
        text-decoration: none;
      }

      .tools-history-edit {
        opacity: 1;

        .icon {
          color: $white;
        }
      }
    }

    &.tools-history-current {
      background-color: $signicat-lighten;

      a {
        color: $signicat;
        text-decoration: none;
      }

      .tools-history-edit {
        opacity: 1;

        .icon {
          color: $signicat;
        }
      }
    }
  }

  .tools-history-header .button:hover,
  input[type="search"].tools-history-filter {
    background-color: $signicat-darken;
  }

  .tools-history-header {
    background-color: $signicat;
  }
}

.context-dropdown-menu li,
.tools-history-SIGNICAT .context-dropdown-menu li {
  background-color: var(--background-color);
  color: var(--text-color);

  &:hover {
    background: var(--inner-background-color);;
    color: var(--text-color);
  }
}
