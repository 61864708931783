.dropzone {
  padding: 50px;
  border-radius: 3px;
  text-align: center;
  font-size: 1rem;
  border: 1px dashed var(--line-color);
  color: var(--text-color);
  user-select: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.drag-active {
  border: dashed 1px $cta-success;
}
