.callout {
  background-color: darken($primary, 24%);

  @include mq(64em) {
    position: fixed;
    top: 41px;
    border-bottom-left-radius: 36px;
  }
}

.callout-logo {
  width: 160px;
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;

  @include mq(52em) {
    width: 200px;
  }
}

.callout-privacy {
  z-index: 100;
  position: relative;
}

.callout a[href="https://x.com/curityio"] > svg path {
  fill: white
}
