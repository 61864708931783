:root {
  --color-signicat: #2a0062;
}

.signicat-icon g rect,
.signicat-icon g path {
  fill: var(--color-signicat);
}

.signicat-icon > g > path {
  fill: white;
}

.modal {
  .signicat-icon g rect,
  .signicat-icon g path {
    fill: white;
  }

  .signicat-icon > g > path {
    fill: var(--color-signicat);
  }
}


[data-color-scheme="light"]  .curity-icon path {
  fill: $primary;
}

[data-color-scheme="light"] .button-primary .curity-icon path {
  fill: white;
}

.standalone[data-color-scheme="null"],
[data-color-scheme="dark"] .curity-icon path,
[data-color-scheme="dark"] .modal-environments .curity-icon path,
.tools-history-workspace-menu .curity-icon path {
  fill: white;
}


[data-color-scheme="dark"] [aria-label="Share on X"] svg path {
  fill: white
}
