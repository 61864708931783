
// Converted Variables


// Custom Media Query Variables


/* Basscss Utility Typography */

.bold    { font-weight: $bold-font-weight /* Fallback value:  bold */ }
.regular { font-weight: normal }
.italic  { font-style: italic }
.caps    { text-transform: uppercase; letter-spacing: .2em; }

.left-align   { text-align: left }
.center       { text-align: center }
.right-align  { text-align: right }
.justify      { text-align: justify }

.nowrap { white-space: nowrap }
.break-word { word-wrap: break-word }

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}