/* Button group */

.button-group {
    display: flex;
}

.button-group-inline {
    display: inline-flex;
}

.button-group .button {
    border-radius: 0;
}

.button-group .button:not(:last-child) {
    border-right: none;
}

.button-group .button:first-of-type {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.button-group .button:last-of-type {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

/* Disabled */

a.button:disabled,
a.button[disabled],
.button:disabled,
.button[disabled],
.button[disabled='disabled'],
button:disabled,
button[disabled='disabled'],
button[disabled] {
    filter: grayscale(100%);
    pointer-events: none;
}

/* Button with a styled checkbox inside */

input[type='checkbox'].button-checkbox {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

input[type='checkbox'].button-checkbox + label {
    align-items: center;
    display: inline-flex;
    margin-bottom: 0.15rem;
    position: relative;
}

/* Focus */

input[type='checkbox'].button-checkbox + label > span {
    align-items: center;
    background: transparent;
    border: 0.1rem solid #ccc;
    border-radius: 1px;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 0.5em;
    outline: 0;
    position: relative;
    width: 18px;
}



/* Grouped button with checkbox inside */
.button-checkbox-group > div:first-child label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.button-checkbox-group > div:not(:first-child, :last-child) label {
    border-radius: 0;
    border-right: none;
}

.button-checkbox-group > div:last-child label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.button-checkbox-group .button:hover {
    background-color: #f6f6f6;
    color: $primary;
}


/* Checked */

input[type='checkbox'].button-checkbox:checked + label > span {
    background: #5ecd62;
    border: 0.1rem solid transparent;
}

/* The checkmark */

input[type='checkbox'].button-checkbox:checked + label > span::before {
    border-bottom: 2px solid white;
    border-color: white;
    border-right: 2px solid white;
    box-sizing: content-box;
    content: '';
    height: 0.6em;
    position: absolute;
    top: 1px;
    transform: rotate(45deg);
    width: 0.3em;
}
