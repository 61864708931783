@font-face {
    font-family: Roboto-Regular;
    font-display: swap;
    src: url("/static_assets/fonts/Roboto-Regular.woff2") format("woff2");
}

@font-face {
    font-family: Roboto-Medium;
    font-display: swap;
    src: url("/static_assets/fonts/Roboto-Medium.woff2") format("woff2");
}

@font-face {
    font-family: Roboto-Mono;
    font-display: swap;
    src: url("/static_assets/fonts/roboto-mono-v6-latin-regular.woff2") format("woff2");
}
