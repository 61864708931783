@charset "utf-8";


/* -------------------------------------------------------------------
Themes for branding. Add more themes in /themes/theme-company.scss
------------------------------------------------------------------- */
@import "themes/themes-curity";

/* -------------------------------------------------------------------
BASSCSS
http://www.basscss.com/docs/
------------------------------------------------------------------- */
@import "../../../static_assets/vendor/basscss-sass/basscss";

/* -------------------------------------------------------------------
Base
------------------------------------------------------------------- */
@import "base/base-media-queries";
@import "base/base-fonts";
@import "base/base-base";
@import "base/base-tables";
@import "base/base-type";
@import "base/base-links";
@import "base/base-keys";
@import "base/base-forms";
@import "base/base-forms-custom";
@import "base/base-buttons";
@import "base/base-button-group";
@import "base/base-utility";
@import "base/base-media";
@import "base/base-errors";
@import "base/base-select";
@import "base/base-dropdown-menu";
@import "base/base-modal";
@import "base/standalone-app";
@import "base/base-vendor-icons";

/* -------------------------------------------------------------------
Components
------------------------------------------------------------------- */
@import "component/component-alert";
@import "component/component-spinner";
@import "component/component-tooltips";
@import "component/component-switch";
@import "component/component-file-dropzone";
@import "component/component-toast";
@import "component/component-notice";
@import "component/activity-bar/activity-bar-base";
@import "component/empty-state/component-empty-state";
@import "component/activity-bar/activity-bar-workspaces";
@import "component/component-cookie-consent-banner";

/* -------------------------------------------------------------------
Layout
------------------------------------------------------------------- */
@import "modules/layout";
@import "modules/sidebar/sidebar-base";
@import "modules/sidebar/sidebar-empty-state";
@import "modules/sidebar/sidebar-guide";
@import "modules/main";
@import "modules/header";
@import "modules/about/about-callout";
@import "modules/about/about-learn-more";
@import "modules/error";

/* -------------------------------------------------------------------
App
------------------------------------------------------------------- */
@import "app/codeflow/illustration";
@import "app/environments/settings";
@import "app/token/jwt/token-input";
@import "app/token/jwt/box-header";
@import "app/token/received/received-token";
@import "app/history/history-base";
@import "app/history/history-badge";
@import "app/history/history-filter";
@import "app/history/history-delete";
@import "app/history/history-header";
@import "app/history/history-group";
@import "app/api/api-base";


html {
	::-webkit-scrollbar {
		background-color: var(--inner-background-color);
		height: 8px;
		width: 8px;
	}

	::-webkit-scrollbar-track {
		border-radius: 6px;
	}

	::-webkit-scrollbar-thumb {
		background-color: lighten($primary, 20%);
		border-radius: 10px;
	}
}
