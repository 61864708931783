
// Converted Variables


// Custom Media Query Variables


/* Basscss Utility Layout */

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.left  { float: left }
.right { float: right }

.fit { max-width: 100% }

.border-box { box-sizing: border-box }