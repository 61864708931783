
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;
$field-focus-color: $blue !default;
$field-success-color: $green !default;
$field-warning-color: $yellow !default;
$field-error-color: $red !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Color Forms */

.field {
  border-style: solid;
  border-width: $border-width;
  border-color: $border-color;
  border-radius: $border-radius;
}

.field:focus,
.field.is-focused {
  outline: none;
  border-color: $field-focus-color;
  box-shadow: 0 0 0 2px color($field-focus-color a(.5));
}

.field:disabled,
.field.is-disabled {
  background-color: $darken-2;
  opacity: .5;
}

.field:read-only:not(select),
.field.is-read-only {
  background-color: $darken-2;
}


.field.is-success {
  border-color: $field-success-color;
}

.field.is-success:focus,
.field.is-success.is-focused {
  box-shadow: 0 0 0 2px color($field-success-color a(.5));
}

.field.is-warning {
  border-color: $field-warning-color;
}

.field.is-warning:focus,
.field.is-warning.is-focused {
  box-shadow: 0 0 0 2px color($field-warning-color a(.5));
}

.field:invalid,
.field.is-error {
  border-color: $field-error-color;
}

.field:invalid:focus,
.field:invalid.is-focused,
.field.is-error:focus,
.field.is-error.is-focused {
  box-shadow: 0 0 0 2px color($field-error-color a(.5));
}


/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/