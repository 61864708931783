
// Converted Variables

$aqua: #7fdbff !default;
$blue: #0074d9 !default;
$navy: #001f3f !default;
$teal: #39cccc !default;
$green: #2ecc40 !default;
$olive: #3d9970 !default;
$lime: #01ff70 !default;
$yellow: #ffdc00 !default;
$orange: #ff851b !default;
$red: #ff4136 !default;
$fuchsia: #f012be !default;
$purple: #b10dc9 !default;
$maroon: #85144b !default;
$white: #fff !default;
$silver: #ddd !default;
$gray: #aaa !default;
$black: #111 !default;
$font-family: 'Helvetica Neue', Helvetica, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: $font-family !default;
$heading-font-weight: bold !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$bold-font-weight: bold !default;
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;
$button-font-size: inherit !default;
$button-font-weight: bold !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$container-width: 64em !default;
$darken-1: rgba(0,0,0,.0625) !default;
$darken-2: rgba(0,0,0,.125) !default;
$darken-3: rgba(0,0,0,.25) !default;
$darken-4: rgba(0,0,0,.5) !default;
$border-width: 1px !default;
$border-radius: 3px !default;
$border-color: $darken-2 !default;

// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Flex Object */

.flex { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex }

.flex-column  { -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column }
.flex-wrap    { -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap }

.flex-center   { -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center }
.flex-baseline { -webkit-box-align: baseline; -webkit-align-items: baseline; -ms-flex-align: baseline; align-items: baseline }
.flex-stretch  { -webkit-box-align: stretch; -webkit-align-items: stretch; -ms-flex-align: stretch; align-items: stretch }
.flex-start    { -webkit-box-align: start; -webkit-align-items: flex-start; -ms-flex-align: start; align-items: flex-start }
.flex-end      { -webkit-box-align: end; -webkit-align-items: flex-end; -ms-flex-align: end; align-items: flex-end }

.flex-justify  { -webkit-box-pack: justify; -webkit-justify-content: space-between; -ms-flex-pack: justify; justify-content: space-between }

.flex-auto { -webkit-box-flex: 1; -webkit-flex: 1 1 auto; -ms-flex: 1 1 auto; flex: 1 1 auto }
.flex-grow { -webkit-box-flex: 1; -webkit-flex: 1 0 auto; -ms-flex: 1 0 auto; flex: 1 0 auto }
.flex-none { -webkit-box-flex: 0; -webkit-flex: none; -ms-flex: none; flex: none }

.flex-first { -webkit-box-ordinal-group: 0; -webkit-order: -1; -ms-flex-order: -1; order: -1 }
.flex-last  { -webkit-box-ordinal-group: 100000; -webkit-order: 99999; -ms-flex-order: 99999; order: 99999 }
@media #{$breakpoint-sm} {
  .sm-flex { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex }
}
@media #{$breakpoint-md} {
  .md-flex { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex }
}
@media #{$breakpoint-lg} {
  .lg-flex { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex }
}


/* Basscss Defaults */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/