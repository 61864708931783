table {
    width: 100%;
    overflow: auto;
    margin-top: 0;
    margin-bottom: 16px;
    border-spacing: 0;
    border-collapse: collapse;
}

table tr {
    border: none;
}

table th,
table td {
    padding: 8px 12px;
    vertical-align: middle;
    border: none;
    font-size: 0.75rem;
}

table tbody tr.delete td {
    border-bottom: 1px solid var(--line-color);
}

table thead th {
    font-weight: normal;
    font-family: var(--type-bold);
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

table .field {
    height: calc(2.5em * 0.75);
    font-size: 0.85rem;
}

table .field-regular {
    height: calc(2.5em * 1.1);
    font-size: 0.85rem;
}

table .field-group .show-password .icon {
    font-size: 0.85rem;
}

.overview-main table.table-fixed,
.table-fixed {
    overflow-x: scroll;

    @include mq(52em) {
        table-layout: fixed;
        display: table;
    }
}

.action-table-wrapper {
    position: relative;
    min-height: 6rem;
}

.action-table {
    border: 1px solid #cbcbcb;
}

.inline-table.action-table tbody tr:nth-child(odd) {
    background-color: transparent;
}


.inline-table.action-table thead th {
    border-bottom: 1px solid #cbcbcb;
    position: relative;
}

.inline-table.action-table td {
    color: #586069;
    font-size: 0.85rem;
}

/* Client table */
[aria-labelledby="client-app-tab"] thead {
    position: sticky;
    inset-block-start: calc(200px - 1rem);
    z-index: 1;
    background: var(--background-color);
}

[aria-labelledby="client-app-tab"] thead th {
    border: none;
}
