.inlineicon {
    margin-right: 0.5rem;
    vertical-align: middle;
    display: inline-block;
}


.icon-yellow {
    color: $tertiary;
}

.primary {
    color: $primary;
}

.secondary {
    color: $secondary;
}

.tertiary {
    color: $tertiary;
}

.section-grey {
    background-color: #fafafa;
}

.uppercase {
    text-transform: uppercase;
}

/* Padding extras */

.pl1 {
    padding-left: 0.5rem;
}

.pr1 {
    padding-right: 0.5rem;
}

.pl2 {
    padding-left: 1rem;
}

.pr2 {
    padding-right: 1rem;
}

/* Margin extras */

.ml1 {
    margin-left: 0.5rem;
}

.mr1 {
    margin-right: 0.5rem;
}

.ml2 {
    margin-left: 1rem;
}

.mr2 {
    margin-right: 1rem;
}

.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/* Line left and right */

.linesides {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    line-height: 0.1em;
}

.linesides span {
    background: #fff;
    padding: 0 1rem;
}

/* Flexbox */

.flex-20 {
    flex: 0 0 20%;
}

.flex-25 {
    flex: 0 0 25%;
}

.flex-30 {
    flex: 0 0 30%;
}

.flex-40 {
    flex: 0 0 40%;
}

.flex-50 {
    flex: 0 0 50%;
}

.flex-60 {
    flex: 0 0 60%;
}

.flex-66 {
    flex: 0 0 66.667%;
}

.flex-70 {
    flex: 0 0 70%;
}

.flex-75 {
    flex: 0 0 75%;
}

.flex-80 {
    flex: 0 0 80%;
}

.flex-100 {
    flex: 0 0 100%;
}

/* Responsive flex helpers */
.md-flex-20 {
    @include mq(52em) {
        flex: 0 0 20%;
    }
}

.md-flex-25 {
    @include mq(52em) {
        flex: 0 0 25%;
    }
}

.md-flex-30 {
    @include mq(52em) {
        flex: 0 0 30%;
    }
}

.md-flex-40 {
    @include mq(52em) {
        flex: 0 0 40%;
    }
}

.md-flex-50 {
    @include mq(52em) {
        flex: 0 0 50%;
    }
}

.md-flex-60 {
    @include mq(52em) {
        flex: 0 0 60%;
    }
}

.md-flex-70 {
    @include mq(52em) {
        flex: 0 0 70%;
    }
}

.md-flex-75 {
    @include mq(52em) {
        flex: 0 0 75%;
    }
}

.md-flex-80 {
    @include mq(52em) {
    flex: 0 0 80%;
    }
}

.md-flex-100 {
    @include mq(52em) {
        flex: 0 0 100%;
    }
}

.inline-flex {
    display: inline-flex;
}

.justify-start {
    justify-content: flex-start;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.flex-gap-1 {
    gap: $space-1;
}

.flex-gap-2 {
    gap: $space-2;
}

.flex-gap-3 {
    gap: $space-3;
}

.flex-gap-4 {
    gap: $space-4;
}

/* TODO @Urban check if this is fine, seems that it fix all the column size issues */
.flex-auto {
    flex-basis: 0;
}

.h100vh {
    height: 50vh;

    @include mq(52em) {
        height: 100vh;
    }
}

@keyframes bounce-left-right {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(-10px);
    }

    60% {
        transform: translateX(-5px);
    }
}

.login-iframe {
    width: 100%;
    min-height: 600px;
    border: none;
}

/* Hiding Content for Accessibility */
.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}


/* Pill */

.pill {
    color: white;
    border: none;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    padding: 0 0.5rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    font-size: 0.75rem;
    border-radius: 1rem;
  }

  .pill-grey {
    background-color: #eee;
    color: #666;
    border-color: #ddd;
  }

  .pill-primary {
    background-color: $primary;
    border-color: $primary;
    color: white;
  }
