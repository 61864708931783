.tools-sidebar ol,
.tools-sidebar ul,
.tools-sidebar p {
  font-size: 0.85rem;
}

.tools-sidebar-guide {
  height: calc(100vh - #{$guide-header});
  display: none;
  top: calc(#{$guide-header} + 45px);
  overflow: hidden auto;
  transition: 0.3s all ease;
  text-align: left;
  padding: 1.5rem;
}

.tools-sidebar-guide.active {
  display: block;
}

.tools-sidebar-guide-number {
  width: 24px;
  height: 24px;
  background-color: $number;
  border-radius: 50%;
  display: inline-flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  font-family: Roboto-Medium, sans-serif;
}

.tools-sidebar-nav {
  top: $header_height;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--line-color);
}
