/* Line numbers */

.tools-input {
  counter-reset: line-numbers;
}

.tools-input .line {
  position: relative;
}

.tools-input .line::before {
  color: #ccd9df;
  font-family: var(--type-mono);
  counter-increment: line-numbers;
  content: counter(line-numbers);
  font-size: 0.75rem;
  margin-right: 1rem;
  width: 1rem;
  padding-left: 0.5em;
  text-indent: -0.5em;
  display: none;
}

/* JSON formatting */
.tools-input-white-bg,
.tools-sidebar-box .tools-input {
  font-size: 0.85rem;
  padding: 1rem;
  margin: 0;
  overflow: auto;
  color: #aaa;
}

.tools-input .key {
  color: $key;
}

.tools-input .string {
  color: $string;
}

.tools-sidebar-box .tools-input .number {
  color: $number;
}

.tools-sidebar {
  border-left: 1px solid var(--line-color);
  padding: 0;
  overflow-x: hidden;
}

.tools-sidebar main {
  padding: 1rem;
}

/* Sidebar box. The 3 boxes */
.tools-sidebar-box {
  position: relative;
  transition: transform 400ms;
}

.tools-sidebar-box-signature {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
  border: none;
  position: relative;
}

fieldset .tools-sidebar-box-signature {
  margin-top: 0;
  padding: 0;
}

fieldset .tools-sidebar-box-content {
  padding: 0;
}

.tools-sidebar-box-content textarea {
  font-family: var(--type-mono);
}

.tools-sidebar-box-content h4 {
  color: #7cbdf8;
  font-size: 1rem;
  border: 1px solid var(--line-color);
  background-color: #fdfdfd;
  display: inline-block;
  border-radius: 2px;
  padding: 0.2rem 1rem;
}

/* Signature */

label[for="tools-sidebar-box-signature-url"] {
  color: #111;
}

input[type="text"]#tools-sidebar-box-signature-url {
  height: 1.75rem;
}

/* Issued at */

.tools-sidebar-box-footer,
.tools-sidebar-box-footer input {
  font-family: var(--type-mono);
  color: grey;
  font-size: 0.85rem;
  margin-top: 1rem;
}

/* Validation errors */

.tools-sidebar-box-validation {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1rem;
  margin: 1rem;
  position: relative;
}

.tools-sidebar-box-validation svg {
  vertical-align: middle;
  display: inline-block;
}

.tools-sidebar-box-validation label {
  font-family: var(--type-mono);
  font-size: 0.85rem;
  line-height: 1;
}




/* Error */
.tools-sidebar-box-validation-error {
  background-color: light-dark(lighten($cta-danger, 50%), transparent);
  border: 0.05rem solid lighten($cta-danger, 30%);

  span {
    color: light-dark($cta-danger, lighten($cta-danger, 30%));
  }

  label {
   color: light-dark($cta-danger, lighten($cta-danger, 30%));
  }
}


/* Warning */
.tools-sidebar-box-validation-warning {
  background-color: light-dark($cta-warning, transparent);
  border: 0.05rem solid darken($cta-warning, 20%);

  span {
    color: light-dark($cta-warning, darken($cta-warning, 10%));
  }

  label {
    color: light-dark($cta-warning, darken($cta-warning, 10%));
  }

  .icon {
    background-color: darken($cta-warning, 25%);
    color: white;
  }
}


.tools-sidebar-box-validation-warning::before {
  background-color: darken($cta-warning, 40%);
}

.tools-sidebar-box-validation-warning::after {
  border: 0.095rem solid darken($cta-warning, 40%);
}

.tools-sidebar-box-validation-error::before {
  background-color: $cta-danger;
}

.tools-sidebar-box-validation-error::after {
  border: 0.095rem solid $cta-danger;
}

/* Connecting line */

.tools-sidebar-box-validation-warning::before,
.tools-sidebar-box-validation-error::before {
  content: "";
  width: 1px;
  height: 14px;
  position: absolute;
  top: -15px;
  left: 26px;
  z-index: 1;
  margin: 0 -2px;
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
  -webkit-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* Connecting dot */

.tools-sidebar-box-validation-warning::after,
.tools-sidebar-box-validation-error::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: -18px;
  left: 20px;
  transform: translateY(-50%) translateZ(0);
}

.tools-sidebar-box-validation div {
  margin-bottom: 0.25rem;
}

.tools-sidebar-box-validation span {
  font-family: var(--type-mono);
  font-size: 0.75rem;
}

.tools-sidebar-box-validation .icon {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Status */

.tools-sidebar-box-signature-status {
  color: $cta-danger;
  font-size: 0.85rem;

  .icon {
    font-size: 1.25rem;
  }
}

.tools-sidebar .tools-sidebar-box-signature {
  border-radius: 4px;
}

.tools-sidebar-box-signature .tools-sidebar-box-content {
  border-left: 1px solid var(--line-color);
  border-right: 1px solid var(--line-color);
  border-bottom: 1px solid var(--line-color);
  padding: 1rem;
  background-color: var(--background-color);
}

.pretty-json {
  color: var(--pretty-json-color);

  .line {
    padding: 0 !important;
    text-indent: 0 !important;
  }

  .key {
    color: var(--pretty-json-variable);
  }

  .boolean, .number {
    color: var(--pretty-json-number);
  }

  .string {
    color: var(--pretty-json-string);
  }

  .comma {
    color: var(--pretty-json-color);
  }

  ::selection {
    color: inherit;
    background-color: var(--pretty-json-selection-background-color);
  }
}
