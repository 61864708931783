.received-token {
  border: 1px solid  var(--line-color);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 4%) 0 2px 4px;
  margin-bottom: 1rem;

  h4 {
    user-select: none;
  }

  .decoded-jwt-data {
    border-bottom: 1px solid var(--line-color);
    cursor: pointer;
  }

  .decoded-jwt-data p {
    margin: 0;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    color: var(--text-color);
  }
}

.received-token-header {
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid var(--line-color)
}

.received-token-1 .received-token-header {
  border-left: 0.25rem solid lighten($received_token1, 10%);
}

.received-token-2 .received-token-header {
  border-left: 0.25rem solid lighten($received_token2, 10%);
}

.received-token-3 .received-token-header {
  border-left: 0.25rem solid lighten($received_token3, 10%);
}

.received-token-4 .received-token-header {
  border-left: 0.25rem solid lighten($received_token4, 10%);
}

.received-token-5 .received-token-header {
  border-left: 0.25rem solid lighten($received_token5, 10%);
}

.received-token-6 .received-token-header {
  border-left: 0.25rem solid lighten($received_token6, 10%);
}

.received-token .tools-box-header {
  background-color: transparent;
  border-bottom: 1px #333;
  border-left: 0;
  padding: 1rem;
}



.received-token .tools-input.tools-input-readonly {
  /* background-color: white; */
  border-bottom: 1px solid var(--line-color);
  color: $key;
}

.received-token .tools-input {
  box-shadow: none;
}

.received-token .button-loading-active::after {
  border: 0.15rem solid $primary;
  border-right-color: transparent;
  border-top-color: transparent;
}

/* Folding */

button[aria-label="Collapse"] svg {
  transition: transform 0.3s ease
}

.received-token.folded {
  .tools-form-token,
  .decoded-jwt-data,
  .tools-sidebar-box {
    display: none;
  }

  button[aria-label="Collapse"] svg {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}

.received-token .tools-sidebar-box {
  border: none;
}


.button.button-expand:focus {
  outline: none;
}

.button.button-expand {
  position: relative;
  box-shadow: none;
  border: none;
  padding: 0 1rem;
  height: 20px;
}
